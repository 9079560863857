import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { BgColor } from '../../models/BgColor';
import { YmSectionProps } from './YmSectionProps';

export const YmSection: React.FC<YmSectionProps> = (props) => {
    let textColorClass = "text-normal";
    let headerColorClass = "text-primary";
    let bgClass = props.bgColor;
    switch (props.bgColor) {
        case BgColor.PRIMARY:
            headerColorClass = "text-white";
            textColorClass = "text-white"
            break;
        default:
            headerColorClass = "text-primary";
            textColorClass = "text-normal";
            break;
    }

    return <section className={`${bgClass} px-3 px-md-5 px-lg-5 py-5 py-md-5`}>
        <Container fluid={props.fluid}>
            <Row>
                <Col md="12" lg="9" className={'p-0'}>
                    <div className={`${textColorClass}`}>{props.name}</div>
                    <h1 className={`mb-5 ${headerColorClass}`}>
                        {props.header}
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col className={'p-0'}>
                    {props.children}
                </Col>
            </Row>
        </Container>
    </section>
}


