import React from 'react';
import classNames from "classnames";
import "./html-viewer.scss";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  html: string;
  modClass?: string;
}

export const YmHtmlViewer = ({ modClass, html }: Props) => {
  return <span className={classNames("html-viewer", modClass)} dangerouslySetInnerHTML={{ __html: html }} />;
};
