import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

const PageDivider: React.FunctionComponent<{ title: string, fluid?: boolean, button: { to: string, text: string } }> = props => {
    return <section className={`bg-white px-3  px-md-5 py-5`}>
        <Container fluid={'xl'}>
            <Row >
                <Col sm="12" lg="8" className={'text-lg-left p-0'}>
                    <h1 className={'text-primary'}>{props.title}</h1>
                </Col>
                {
                    props.button &&
                    <Col sm="12" lg="4" className={'text-center text-lg-right mt-4  mt-lg-0'}>

                        <Link to={`${props.button.to}`}>
                            <Button color={'primary'} size="xl" className="btn-pill px-5 py-2"><span>{props.button.text}</span></Button>
                        </Link>
                    </Col>
                }
            </Row>
        </Container>
    </section>
}

export default PageDivider;