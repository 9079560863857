import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';

import LandingLayout from '../layouts/LandingLayout';
import ContentLayout from '../layouts/ContentLayout';

import Start from '../pages/main/Start'
import About from '../pages/main/About';
import Expertise from '../pages/main/Expertise';
import Partner from '../pages/main/Partner';
import Solutions from '../pages/main/Solutions';
import Products from '../pages/main/Products';
import SmartCity from "../pages/main/SmartCity";
import WhatWeDo from '../pages/content/WhatWeDo';
import Business from '../pages/content/Business';
import Contact from '../pages/content/Contact';
import Career from "../pages/main/Career";
import { Container, Row, Col } from 'reactstrap';
import TopNavigation from '../components/TopNavigation';
import { BgColor } from '../models/BgColor';
import LivingApp from '../pages/main/LivingApp'


const childRoutes = (Layout: any, routes: any[], { ...rest }) =>
    routes.map(({ path, component: Component }, index) => {
        return (
            <Route
                key={index}
                path={path}
                exact
                render={(props: any) => (
                    <Layout>
                        <Component {...props} {...rest} />
                    </Layout>
                )}
            />
        )
    }
    );

const Page404: React.FC = props => {
    return <ContentLayout>
        <TopNavigation match={null} bgColor={BgColor.WHITE}></TopNavigation>
        <Container fluid="xl" style={{ height: '70vh' }} className="py-7 margin-auto">
            <Row>
                <Col>
                    <div className="py-7">
                        <h1 className="text-primary text-center">Sorry, we couldn’t find the page you were looking for.</h1>
                        <div className="text-center"><p>Make sure you typed the url correctly.</p></div>
                    </div>
                </Col>
            </Row>
        </Container>
    </ContentLayout>
}

export interface RoutesProps {
    supportsWebp: boolean;
}

export default class Routes extends React.Component<RoutesProps, {}> {

    private _startRoutes: any[];
    private _pagesRoutes: any[];
    constructor(props: any) {
        super(props);
        this._startRoutes = [{ path: '/', component: Start }];
        this._pagesRoutes = [
            { path: '/products', component: Products },
            { path: '/solutions', component: Solutions },
            { path: '/expertise', component: Expertise },
            { path: '/partners', component: Partner },
            { path: '/about', component: About },
            { path: '/what-we-do', component: WhatWeDo },
            { path: '/business', component: Business },
            { path: '/contact', component: Contact },
            { path: '/career', component: Career },
            { path: '/smartcity2023', component: SmartCity },
            { path: '/livingapp', component: LivingApp },
        ];
    }
    render() {
        return (
            <ScrollToTop>
                <Switch>
                    {childRoutes(LandingLayout, this._startRoutes, { ...this.props })}
                    {childRoutes(ContentLayout, this._pagesRoutes, { ...this.props })}
                    <Route
                        render={(props) => {
                            switch (props.location.pathname.toLocaleLowerCase()) {
                                case '/welcome.html':
                                    return <Redirect to="/"></Redirect>
                                case '/our-promise':
                                case '/discover':
                                case '/end-to-end-iot':
                                    return <Redirect to="/what-we-do"></Redirect>
                                case '/managed-iot':
                                    return <Redirect to='/expertise'></Redirect>
                                default:
                                    return (<Page404></Page404>)
                            }
                        }} />
                </Switch>
            </ScrollToTop>
        )
    }
}
