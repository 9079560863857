import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import carouselSolutionsJPG from '../../assets/img/start/youmoni-our-solutions.jpg';
import carouselPartnersJPG from '../../assets/img/start/youmoni-our-partners.jpg';
import carouselProductsJPG from '../../assets/img/start/youmoni-our-products.jpg';
import carouselSolutionsWebp from '../../assets/img/start/youmoni-our-solutions.webp';
import carouselPartnersWebp from '../../assets/img/start/youmoni-our-partners.webp';
import carouselProductsWebp from '../../assets/img/start/youmoni-our-products.webp';

import businessJPG from '../../assets/img/start/youmoni-business.jpg';
import personalJPG from '../../assets/img//start/youmoni-personal.jpg';
import businessWebp from '../../assets/img/start/youmoni-business.webp';
import personalWebp from '../../assets/img//start/youmoni-personal.webp';

import expertiseJPG from '../../assets/img/start/youmoni-services.jpg';
import productsJPG from '../../assets/img/start/youmoni-software.jpg';
import solutionsJPG from '../../assets/img/start/youmoni-industry.jpg';
import expertiseWebp from '../../assets/img/start/youmoni-services.webp';
import productsWebp from '../../assets/img/start/youmoni-software.webp';
import solutionsWebp from '../../assets/img/start/youmoni-industry.webp';
import goesFurtherJpg from '../../assets/img/start/youmoni-clarity-reality.webp';
import goesFurtherWebp from '../../assets/img/start/youmoni-clarity-reality.webp';



import connect_gif from '../../assets/img/Youmoni_Connect.gif';
import visualize_gif from '../../assets/img/Youmoni_Visualise.gif';
import analyze_gif from '../../assets/img/Youmoni_Analyse.gif';
import react_gif from '../../assets/img/Youmoni_React.gif';

import PageDivider from '../../components/PageDivider';
import { YmHeader, HeaderVideo } from '../../components/YmHeader';
import { YmTextBlock } from '../../components/YmTextBlock';
import { BgColor } from '../../models/BgColor';
import { YmCarousel } from '../../components/YmCarousel';
import { YmImageBlock } from '../../components/YmImageBlock';
import { YmSection } from '../../components/YmSection';
import { GetText } from '../../components/YmAppText/YmAppText';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';


const Header = (props: any): JSX.Element => {
    const links = [{to:'/what-we-do',text:'What we do'},{to:'/products',text:'IoT platform & sensors'}]
    return <YmHeader
        {...props}
        title={''}
        media={<HeaderVideo></HeaderVideo>} 
        links={links}
    ></YmHeader>
}

const WhatWeDo = (): JSX.Element => {
    const items = [
        { title: GetText('landing.whatwedo.connect.headline'), image: connect_gif, text: "We connect your things by adding sensors or by using existing sensors and data interfaces. An IoT gateway sends the data to the Youmoni IoT cloud where it’s stored, visualized, processed and analyzed." },
        { title: GetText('landing.whatwedo.visualize.headline'), image: visualize_gif, text: "To maximize your insights, we visualize your data to make it easy to understand. The Youmoni Cloud has a user interface called the Console, which can easily be branded with your companiy design, where the connected things’ location, sensor status and notifications are presented." },
        { title: GetText('landing.whatwedo.analyze.headline'), image: analyze_gif, text: "Once connected, all things' data are stored in the Youmoni Cloud, new insights and patterns will be recognized, improving your products and processes. Youmoni data can also be mixed with data from other sources. Combined, new discoveries will emerge." },
        { title: GetText('landing.whatwedo.react.headline'), image: react_gif, text: "When products report data in near real time, the Youmoni Cloud will discover harmful patterns and let the system act before it’s too late. The Youmoni Cloud can be trained to react to changes in patterns and notify and control processes if specific patterns or events occur. " },
    ]
    return <YmSection
        bgColor={BgColor.PRIMARY}
        fluid={'xl'}
        name={"What we do"}
        header={"We connect your things and bring them online. Monitor, control and automate how your things behave, wherever they are. "}>
        <Row>
            {items.map((item, index) => {
                let className = 'pr-0 pr-md-5'
                return <Col key={`intro_block_${index}`} style={{position:'relative'}} className={'mb-5'} xs="12" sm="6" lg="3">
                    <div className="p-4 text-center"><img className={'w-100'} style={{ maxWidth: "250px" }} src={item.image} alt={item.title} /></div>
                    <div className={`px-0 mb-5 ${className}`}>
                        <YmTextBlock
                            bgColor={BgColor.PRIMARY}
                            header={item.title}
                            content={item.text}
                        />
                    </div>
                    <div style={{position:"absolute", bottom:0}} className="mt-4"><Link className="text-white" to="/what-we-do#learn">Learn more <FontAwesomeIcon className="ml-2" size={'1x'} icon={faLongArrowAltRight}></FontAwesomeIcon></Link></div>
                </Col>
            })
            }
        </Row>
    </YmSection>
}

const LetTheCloudDiscover = (): JSX.Element => {
    return <PageDivider fluid={false} button={{ to: "/what-we-do#discover", text: GetText("btn.readmore") }} title={GetText("landing.discover.headline")}></PageDivider>
}

const PagesCarousel = (props:any): JSX.Element => {
    
    const items = [
        { src: props.supportsWebp ? carouselPartnersWebp : carouselPartnersJPG, caption: "Youmoni Living App and smart property", link: '/livingapp' },
    ]

    return <section className={'vh-75'}><Container fluid>
        <Row>
            <Col className={`p-0`}>
                <YmCarousel items={items}></YmCarousel>
            </Col>
        </Row>
    </Container>
    </section>
}

const Carousel = (props:any): JSX.Element => {
    
    const items = [
        { src: props.supportsWebp ? carouselSolutionsWebp : carouselSolutionsJPG, caption: "Our Solutions", link: '/solutions' },
        { src: props.supportsWebp ? carouselPartnersWebp : carouselPartnersJPG, caption: "Our Partners", link: '/partners' },
        { src: props.supportsWebp ? carouselProductsWebp : carouselProductsJPG, caption: "Our Products", link: '/products' }
    ]

    return <section className={'vh-75'}><Container fluid>
        <Row>
            <Col className={`p-0`}>
                <YmCarousel items={items}></YmCarousel>
            </Col>
        </Row>
    </Container>
    </section>
}

const OurServices = (props:any): JSX.Element => {
    const expertiseImage = props.supportsWebp ? expertiseWebp :  expertiseJPG;
    const productsImage = props.supportsWebp ? productsWebp :  productsJPG;
    const solutionsImage = props.supportsWebp ? solutionsWebp :  solutionsJPG;

    const items = [
        { title: "Expertise", to:"/expertise" , image: expertiseImage, text: "Our experienced IoT team and partners have the expertise needed to transform your business to become a leader in your business vertical. By putting your things online, new opportunities to save costs, new operational models and revenue streams will emerge." },
        { title: "Products", to:"/products", image: productsImage, text: "Youmoni delivers a complete product suite for IoT solutions. It includes multi-tenant IoT cloud and hardware products. Ready-to-use applications can be used from start and often just need an adapted brand experience." },
        { title: "Solutions", to:"/solutions", image: solutionsImage, text: "The Youmoni Solutions packages enable making your things online. Some of the solutions are ready to use and include packaged cloud application and integrated hardware. With no or small adaptations it will accelerate your IoT project." },
    ]

    return <YmSection
        bgColor={BgColor.WHITE}
        fluid={'xl'}
        name={``}
        header={`Our Service`}>
        <Row>
            {items.map((item, index) => {
                return <Col key={`intro_block_${index}`} className={`mb-5 px-3 pr-md-4 pr-lg-5`} sm="12" md="4" lg="4">
                    <Link className={"text-decoration-none"} to={item.to}>
                    <div style={{ height: '250px', width: '100%', backgroundSize:'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat', backgroundImage: `url('${item.image}')` }}></div>
                    <div className={'my-4'}>
                        <YmTextBlock
                            bgColor={BgColor.WHITE}
                            header={item.title}
                            content={item.text}
                        />
                    </div>
                    </Link>
                </Col>
            })
            }
        </Row>
    </YmSection>
}

const BusinessPersonal = (props:any): JSX.Element => {
    const businessImage = props.supportsWebp ? businessWebp : businessJPG;
    const personalImage = props.supportsWebp ? personalWebp : personalJPG;
    return <div className="px-2 pt-5 px-lg-4"><Container fluid>
        <Row>
            <Col sm="12" md="6" className={'px-2 px-md-0 pl-md-2 pr-md-3 pb-5'}>
                <YmImageBlock
                    media={(
                        <div className="position-relative">
                            <div className={'cover-scroll'} style={{ height: '80vh', width: '100%', backgroundImage: `url('${businessImage}')` }}></div>
                            <div className='opacity-black position-absolute' style={{ height: '80vh', width: '100%', top: 0 }}></div>
                        </div>

                    )}
                    header={'Business'}
                    content={(
                        <div>
                            <h3 className={'text-white'}>Pay as you go. Free IoT cloud tiers available.</h3>
                            <Link to='/business'>
                            <Button color={'primary'} size={'lg'} className={'btn-pill py-2 px-5 mt-4'}> Read More</Button>
                            </Link>
                            
                        </div>
                    )}
                ></YmImageBlock>

            </Col>
            <Col sm="12" md="6" className={'px-2 px-md-0 pl-md-2 pr-md-3 pb-5'}>
                <YmImageBlock
                    media={(
                        <div className="position-relative">
                            <div className={'cover-scroll'} style={{ height: '80vh', width: '100%', backgroundImage: `url('${personalImage}')` }}></div>
                            <div className='opacity-black position-absolute' style={{ height: '80vh', width: '100%', top: 0 }}></div>
                        </div>
                    )}
                    header={'Personal'}
                    content={(
                        <div>
                            <h3 className={'text-white'}>Soon to be released</h3>
                            <Button disabled={true} color={'primary'} size={'lg'} className={'btn-pill py-2 px-5 mt-4'}> Read More</Button>
                        </div>
                    )}
                ></YmImageBlock>

            </Col>
        </Row>
    </Container>
    </div>
}

const GoesFurther = (props:any): JSX.Element => {
    const image = props.supportsWebp ? goesFurtherWebp : goesFurtherJpg;
    return <div><Container fluid>
        <Row>
            <Col xs="12" className={'p-0'}>
                <YmImageBlock
                    media={(<div className="cover-scroll" style={{ height: '75vh', width: '100%', backgroundImage: `url('${image}')` }}></div>)}
                    header={'Our promise goes further. Clarity a reality.'}
                    content={(
                        <div className={'mt-5'}>
                            <Link to={"/what-we-do#promise"}>
                                <Button color={'primary'} size={'xl'} className={'btn-pill py-2 px-5'}> {GetText("btn.readmore")}</Button>
                            </Link>

                        </div>
                    )}
                ></YmImageBlock>
            </Col>
        </Row>
    </Container>
    </div>

}

const Start: React.FC<{ match: any }> = props => {

    return (<div className="start">
        <Header {...props}></Header>
        <PagesCarousel {...props}></PagesCarousel>
        <WhatWeDo></WhatWeDo>
        <LetTheCloudDiscover></LetTheCloudDiscover>
        <Carousel {...props}></Carousel>
        <BusinessPersonal {...props}></BusinessPersonal>
        <GoesFurther {...props}></GoesFurther>
        <OurServices {...props}></OurServices>
    </div>
    );
}
export default Start;    