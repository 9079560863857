
import React from 'react';
import { Container, Row, Col,} from "reactstrap";
import { NewsLetterForm } from '../../Forms/NewsLetterForm';
import linkedIn from '../../assets/img/social/linkedIn.svg';
import { Link } from 'react-router-dom';

export const YmFooter: React.FunctionComponent<{}> = (props) => {
    return <section className={`footer border-top border-5 border-secondary w-100 bg-gray py-5 px-3 px-md-5 `}>
        <Container fluid={'xl'}>
            <Row className={'mb-5'}>
                <Col lg="8" className="px-0">
                    <h3 className={'text-primary'}>Keep up to date</h3>
                    <div>Keep up to date with our latest news and analysis by subscribing to our newsletter.</div>
                </Col>
                <Col lg="4" className="px-0">
                    <NewsLetterForm></NewsLetterForm>
                </Col>   
            </Row>
            <Row>
                <Col xs="12" sm="6" lg="4" className="px-0 mb-5">
                    <h3 className={'text-dark'}>Explore</h3>
                    <div><Link to="/solutions" className="footer-link">Solutions</Link></div>
                    <div><Link to="/products" className="footer-link">Products</Link></div>

                </Col>
                <Col xs="12" sm="6" lg="4" className="px-0 mb-5">
                    <h3 className={'text-dark'}>Tools</h3>
                    <div> <a className="footer-link text-decoration-none" target="_blank" rel="noopener noreferrer" href="https://youmoni.atlassian.net/servicedesk/">Service Desk</a></div>
                </Col>
                <Col sm="12" md="6" lg="4" className="px-0 mb-5">
                    <Row>
                        <Col xs="6" sm="6" md="4" lg="4">
                            <h3 className={'text-dark'}>Learn</h3>
                            <div><Link className="text-decoration-none text-black" to="/what-we-do">What we do</Link></div>
                            <div><Link className="text-decoration-none text-black" to="/expertise">Expertise</Link></div>
                            <div><Link className="text-decoration-none text-black" to="/about">About</Link></div>
                        </Col>
                        <Col xs="6" sm="6" md="4" lg="4"  className="mb-5">
                        <h3 className={'text-dark'}>Connect</h3>
                        <div><Link className="text-decoration-none text-black" to="/contact">Contact</Link></div>
                        </Col>
                        <Col sm="6" md="4" lg="4" className="mt-5 mt-md-0">
                        <h3 className={'text-dark'}>Follow</h3>
                        <a className="text-decoration-none" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/youmoni"><img style={{height:'25px'}} src={linkedIn} alt="in"></img></a>
                        </Col>
                    </Row>
                </Col>

            </Row>
        </Container>
    </section>
}
export default YmFooter;