import React from "react";
import { Container, Row, Spinner, Modal, ModalBody } from "reactstrap";

const Loader: React.FunctionComponent<{ isOpen: boolean }> = props => {
  return (<Modal backdropClassName={"bg-white"} contentClassName={"bg-transparent"} fade={false} size="md" centered={true} isOpen={props.isOpen}>
    <ModalBody>
      <Container fluid className="vh-50 d-flex">
        <Row className="justify-content-center align-self-center w-100 text-center bg-transparent">
          <Spinner color="primary" />
        </Row>
      </Container>
    </ModalBody>
  </Modal>
  );
}

export default Loader;