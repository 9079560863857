import React from "react";
import { Container, Row, Col, Navbar, Nav, NavItem, NavLink, NavbarBrand, NavbarToggler, Collapse } from "reactstrap";
import { Link } from 'react-router-dom';
import logo from '../assets/img/youmoni-logo.svg';
import { BgColor } from "../models/BgColor";

export interface TopNavigationState {
    isMenuOpen: boolean;
}
export interface TopNavigationProps {
    match: any;
    bgColor?: BgColor;
}

class TopNavigation extends React.Component<TopNavigationProps, TopNavigationState> {

    constructor(props: any) {
        super(props);
        this.state = {
            isMenuOpen: false
        }
    }

    private _onMenuToggle = () => {
        const { isMenuOpen } = this.state;
        this.setState({ isMenuOpen: !isMenuOpen });
    }

    render() {
        const { isMenuOpen } = this.state;
        const { match, bgColor } = this.props;
        const current = match && match.path ? match.path : '';
        const className = bgColor ? bgColor : '';

        const color = isMenuOpen ? 'bg-primary' : '';
        const deskTopClass = "d-none d-lg-inline";
        const deviceClass = "pl-4 d-inline-block d-lg-none";
        return (
            <div className={`w-100 ${color} ${!isMenuOpen ? className : ''}`} style={{ position: "absolute", top: "0", left: "0" }}>
                <Container fluid={true}>
                    <Row>
                        <Col xs="12" className="px-0 border-top border-5 border-secondary">
                            <Navbar expand="lg" className="pt-3 pl-3 pl-md-5 pr-3 pr-md-5 pr-lg-0" >
                                <NavbarBrand className={''} tag={Link} to="/"><img src={logo} alt="youmoni" /></NavbarBrand>
                                <div className={'ml-auto'}>
                                    <NavbarToggler onClick={this._onMenuToggle} className={`d-inline-block d-lg-none ${isMenuOpen ? 'change' : ''}`} >
                                        <div className={`burger-1`}></div>
                                        <div className={`burger-2`}></div>
                                        <div className={`burger-3`}></div>
                                    </NavbarToggler>
                                </div>
                                {
                                    isMenuOpen &&
                                    <div className={`bg-primary w-100`}>
                                        <Collapse isOpen={isMenuOpen} navbar style={{ zIndex: 999, position: 'absolute', left: 0, width: '100%' }}>
                                            <div style={{ height: '100vh' }} className={`${deviceClass} bg-primary w-100`}>
                                                <Nav navbar>
                                                    <NavItem className="">
                                                        <NavLink tag={Link} to="/solutions" active={current === "/solutions"}>
                                                            Solutions
                                                    </NavLink>
                                                    </NavItem>
                                                    <NavItem className="">
                                                        <NavLink tag={Link} to="/products" active={current === "/products"}>
                                                            Products
                                                    </NavLink>
                                                    </NavItem>
                                                    <NavItem className="">
                                                        <NavLink tag={Link} to="/expertise" active={current === "/expertise"}>
                                                            Expertise
                                                    </NavLink>
                                                    </NavItem>
                                                    <NavItem className="">
                                                        <NavLink tag={Link} to="/partners" active={current === "/partners"}>
                                                            Partners
                                                    </NavLink>
                                                    </NavItem>
                                                    <NavItem className="">
                                                        <NavLink tag={Link} to="/business" active={current === "/business"}>
                                                            Pricing
                                                    </NavLink>
                                                    </NavItem>
                                                    <NavItem className="">
                                                        <NavLink tag={Link} to="/about" active={current === "/about"}>
                                                            About
                                                    </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </div>
                                        </Collapse>
                                    </div>
                                }

                                <Nav navbar className={'pr-2 pr-lg-5'}>
                                    <NavItem className={`${deskTopClass}`}>
                                        <NavLink tag={Link} to="/solutions" active={current === "/solutions"}>
                                            Solutions
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className={`${deskTopClass}`}>
                                        <NavLink tag={Link} to="/products" active={current === "/products"}>
                                            Products
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className={`${deskTopClass}`}>
                                        <NavLink tag={Link} to="/expertise" active={current === "/expertise"}>
                                            Expertise
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className={`${deskTopClass}`}>
                                        <NavLink tag={Link} to="/partners" active={current === "/partners"}>
                                            Partners
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className={`${deskTopClass}`}>
                                        <NavLink tag={Link} to="/business" active={current === "/business"}>
                                            Pricing
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className={`${deskTopClass}`}>
                                        <NavLink tag={Link} to="/about" active={current === "/about"}>
                                            About
                                         </NavLink>
                                    </NavItem>
                                </Nav>
                            </Navbar>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default TopNavigation
