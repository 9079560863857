import React from 'react';
import { Container, Row, Col, Collapse } from 'reactstrap';
import { BgColor } from '../../models/BgColor';
import { YmCollapseRowProps } from './YmCollapseRowProps';
import { YmCollapseRowState } from './YmCollapseRowState';

export class YmCollapseRow extends React.Component<YmCollapseRowProps, YmCollapseRowState> {

    constructor(props: YmCollapseRowProps) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    public render() {
        const { header, ingress, content, collapseContent, bgColor, name } = this.props;
        const { isOpen } = this.state;
        const columnClass1 = 'mb-md-0 pr-md-3';
        const columnClass2 = 'mb-md-0 pl-md-3';

        let textColorClass = "text-normal";
        let headerColorClass = "text-primary";
        let linkColorClass = "text-white";
        let bgClass = bgColor;
        switch (bgColor) {
            case BgColor.PRIMARY:
                headerColorClass = "text-white";
                textColorClass = "text-white"
                break;
            default:
                headerColorClass = "text-primary";
                textColorClass = "text-normal";
                linkColorClass = "text-primary";
                break;
        }

        return <div className={`${bgClass} pt-5 pt-lg-7 pb-0`}>
            <section className="px-3 px-md-5 pb-0 pb-md-5">
                <Container fluid="xl" className="px-0">
                    <Row>
                        <Col sm="12">
                            {
                                name && name !== '' &&
                                <div className={`${textColorClass}`}>{name}</div>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="6" className={`${columnClass1}`}>
                            <h1 className={`${headerColorClass}`}>{header}</h1>
                        </Col>
                        <Col sm="12" md="6" className={`${columnClass2}`}>
                            <div className="">
                                <div className={`${textColorClass}`}>
                                    {ingress}
                                </div>
                                {
                                    collapseContent &&
                                    <div className='mt-4 mb-5'>
                                        <span className={`${linkColorClass} cursor-pointer`} onClick={() => { this.setState({ isOpen: !isOpen }) }}>
                                            {isOpen ? <span style={{ fontWeight: 600 }}><b>Let’s get technical  -</b></span> : <span style={{ fontWeight: 600 }}><b>Let’s get technical +</b></span>}
                                        </span>
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Collapse isOpen={isOpen}>
                {collapseContent}
            </Collapse>
            {
                <div>
                    {content}
                </div>
            }
        </div>
    }
}