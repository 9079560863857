import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import { YmHeader, HeaderImage } from '../../components/YmHeader';
import { YmTextBlock } from '../../components/YmTextBlock';
import { BgColor } from '../../models/BgColor';
import { YmSection } from '../../components/YmSection';
import { GetText } from '../../components/YmAppText';
import { YmCollapseRow } from '../../components/YmCollapseRow';
import { YmImageBlock } from '../../components/YmImageBlock';
import OurPromise from './OurPromise';
import CloudDiscover from './CloudDiscover';
import { ContactUs } from '../../content/ContactUs';

import connect_gif from '../../assets/img/Youmoni_Connect.gif';
import visualize_gif from '../../assets/img/Youmoni_Visualise.gif';
import analyze_gif from '../../assets/img/Youmoni_Analyse.gif';
import react_gif from '../../assets/img/Youmoni_React.gif';

import whatwedoJpg from '../../assets/img/youmoni-what-we-do.jpg';
import whatwedoWebp from '../../assets/img/youmoni-what-we-do.webp';

import illustration from '../../assets/img/youmoni-what-we-do-illustration.png';
import onion from '../../assets/img/youmoni-onion.png';

import promiseJpg from '../../assets/img/youmoni-promise.jpg';
import promiseWebp from '../../assets/img/youmoni-promise.webp';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';




const IntroSection = () => {
    return <div><YmCollapseRow
        bgColor={BgColor.WHITE}
        name={""}
        header={"We connect your things and bring them online. Monitor your things wherever they are."}
        ingress={<div>
            <p>Youmoni delivers IoT, end to end.</p>
            <p>Youmoni has the expertise, ready-to-use IoT cloud software, integrated hardware and sensors needed to bring your things online in days. It can be machines, vehicles, buildings, city, retail stores and or industrial things that need to be digitized, tracked, remotely monitored and controlled or even integrated into business processes.</p>
            <p>With our ready-to-use IoT product portfolio we can digitize your tings in days not months.</p>
            <p>Customers use the Youmoni IoT cloud and hardware to either gather and analyse sensor data from existing sensors or add new sensors to offline machines, bringing them online. Data and insights can be presented in a ready to use console for internal usage and or in apps for end customers. </p>
            <p>User interfaces in dashboards and apps can easily be adapted with your brand, add new components and business processes.</p>
            <p>
                
            <Link to="/products">Learn more about or IoT Platform<FontAwesomeIcon className="mx-2" size={'1x'} icon={faLongArrowAltRight}></FontAwesomeIcon></Link>
            </p>
        </div>}
    ></YmCollapseRow>
        <Container fluid="xl">
            <Row>
                <Col xs="12" className={'p-0 pb-5'}>
                    <YmImageBlock
                        media={(
                            <div className="position-relative">
                                <img style={{ width: '100%' }} alt="IoT End to End" src={illustration}></img>
                            </div>
                        )}
                    ></YmImageBlock>
                </Col>
            </Row>
        </Container>
    </div>
}

const WhatWeDoIntro = () => {
    return <div><YmCollapseRow
        bgColor={BgColor.PRIMARY}
        name={""}
        header={"Youmoni IoT cloud platform. From idea to industrial scale"}
        ingress={<div style={{ backgroundColor: '#052dc6' }}>
            <p>
                Youmoni IoT cloud is an end-to-end cloud that enables you to bring IoT services to the market faster. It will drive your digitalization of physical business processes forward and enable you to really enable your IoT business models. Quickly move from idea to prototype, proof of concept and roll-out with scale.
           </p>
            <p>
            <Link className="text-white" to="/products">Learn more about or IoT Platform<FontAwesomeIcon className="mx-2" size={'1x'} icon={faLongArrowAltRight}></FontAwesomeIcon></Link>
            </p>

        </div>}
        content={
            <div>
                <Container fluid style={{ backgroundColor: '#052dc6' }} >
                    <Row>
                        <Col xs="12" className={'text-center'}>
                            <div style={{ height: '70vh', backgroundSize: "contain", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: '100%', backgroundImage: `url(${onion})` }}></div>
                        </Col>
                    </Row>
                </Container>
            </div>
        }
    ></YmCollapseRow>
    </div>
}

const WhatWeDoSection = (): JSX.Element => {
    return <YmSection
        bgColor={BgColor.PRIMARY}
        fluid={'xl'}
        name={''}
        header={``}>
        <Row className={"mb-4"}>
            <Col sm="12" md="6" className={'order-1 order-md-1 pb-4 pb-md-0 my-auto'}><div className="text-center p-4 w-100"><img src={connect_gif} alt="connect" /></div></Col>
            <Col sm="12" md="6" className={'order-2 order-md-2 my-auto'}>
                <YmTextBlock header={"Connect"}
                    content={`${GetText('landing.whatwedo.connect.text')}`} bgColor={BgColor.PRIMARY}></YmTextBlock>
            </Col>
        </Row>
        <Row className={"mb-4"}>
            <Col sm="12" md="6" className={'order-2 order-md-1 my-auto'}>
                <YmTextBlock header={"Visualize"}
                    content={`${GetText('landing.whatwedo.visualize.text')}`} bgColor={BgColor.PRIMARY}>
                </YmTextBlock>
            </Col>
            <Col sm="12" md="6" className={'order-1 order-md-2 pb-4 pb-md-0 my-auto'}><div className="text-center p-4 w-100"><img src={visualize_gif} alt="visualize" /></div></Col>
        </Row>
        <Row className={"mb-4"}>
            <Col sm="12" md="6" className={'order-1 order-md-1 pb-4 pb-md-0 my-auto'}><div className="text-center p-4 w-100"><img src={analyze_gif} alt="analyse" /></div></Col>
            <Col sm="12" md="6" className={'order-2 order-md-2 my-auto'}>
                <YmTextBlock header={"Analyze"}
                    content={`${GetText('landing.whatwedo.analyze.text')}`} bgColor={BgColor.PRIMARY}>
                </YmTextBlock></Col>
        </Row>
        <Row className={"mb-4"}>
            <Col sm="12" md="6" className={'order-2 order-md-1 my-auto'}>
                <YmTextBlock header={"React"}
                    content={`${GetText('landing.whatwedo.react.text')}`}
                    bgColor={BgColor.PRIMARY}></YmTextBlock>
            </Col>
            <Col sm="12" md="6" className={'order-1 order-md-2 pb-4 pb-md-0 my-auto'}><div className="text-center p-4 w-100"><img src={react_gif} alt="react" /></div></Col>
        </Row>
    </YmSection>
}

const ImageDivider = (props:any): JSX.Element => {
    const promiseImage = props.supportsWebp ? promiseWebp: promiseJpg;
    return <Container fluid>
        <Row>
            <Col xs="12" className={'p-0'}>
                <YmImageBlock
                    media={(<div style={{ height: '50vh', width: '100%', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: `url('${promiseImage}')` }}></div>)}
                ></YmImageBlock>
            </Col>
        </Row>
    </Container>
}


const OurEnvironment = () => {
    return <YmCollapseRow
        bgColor={BgColor.WHITE}
        header={"Our common environment"}
        ingress={<div>
            <p>Youmoni is committed to delivering solutions for a better future. We know that sensors give new vital insights about how our cities, energy production, industrial processes, vehicles affect and contribute to emissions, pollution levels etc.  </p>
            <p>That's why we invest in and focus on solutions and business areas where we can help our customers and our planet to be more sustainable. We focus on energy efficiency in buildings, industrial processes, logistics, and environmental sensors. Sensor data from connected things will be used to improve insights for our environment even more. With analytics and AI we can prepare for a better tomorrow and save money at the same time.</p>
            <p><p> <Link to="/solutions">Learn more about or solutions<FontAwesomeIcon className="mx-2" size={'1x'} icon={faLongArrowAltRight}></FontAwesomeIcon></Link></p></p>
        </div>}
        content={
            <div>
                <section className="px-3 px-md-5 py-5">
                    <Container fluid="xl">
                        <Row>
                            <Col sm="12" md="6" className={`px-0 pr-md-5`}>
                                <YmTextBlock
                                    header={"Our promise goes further"}
                                    content={`The Youmoni cloud is free to use for environmental research projects related to environmental data series. The data and the results must be shared with everyone via youmoni.com. 
                        Discounts are also available for customers that share data series data relevant for environmental research.  
                        By using Youmoni you help the environmental research community.
                        `}
                                ></YmTextBlock>
                            </Col>
                            <Col sm="12" md="6" className={`px-0 px-0 pl-md-3`}>
                                <YmTextBlock
                                    header={"IoT is win win for you and the environment"}
                                    content={`Imagine. If  your building, generator, engine solar panels equipment or similar energy related equipment  is running at the wrong pace, Youmoni cloud can alert you, and with its algorithms change the running behaviour, extending the products life cycle and save energy for the benefit of our environment.`}
                                ></YmTextBlock>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        }
    >
    </YmCollapseRow>
}

class WhatWeDo extends React.Component<{match:any,location:any,supportsWebp:boolean}> {
    private promiseRef = React.createRef<HTMLDivElement>();
    private discoverRef = React.createRef<HTMLDivElement>();
    private learnRef = React.createRef<HTMLDivElement>();

    componentDidMount(){
        const {location} = this.props;
        this.scrollInto(location);
    }

    private  scrollInto = (location: any) => {
        if (location && location.hash) {
            let node: HTMLDivElement | null = null;
            switch (location.hash) {
                case '#promise':
                    node = this.promiseRef.current
                    break;
                case '#discover':
                    node = this.discoverRef.current
                    break;
                case '#learn':
                    node = this.learnRef.current
                    break;
                default:
                    break;
            }
            if (node) {
                node.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    render()
    {
        const headerImage = this.props.supportsWebp ? whatwedoWebp : whatwedoJpg;
        return (<div className="whatwedo">
        <YmHeader {...this.props} title="We connect your things and bring them online. For you, your customers and our common environment." media={<HeaderImage image={headerImage}></HeaderImage>}></YmHeader>
        <div id="learn" ref={this.learnRef}>
        <IntroSection></IntroSection>
        <WhatWeDoIntro></WhatWeDoIntro>
        <WhatWeDoSection></WhatWeDoSection>
        </div>
        <div id="promise" ref={this.promiseRef}>
            <OurPromise {...this.props}></OurPromise>
        </div>
        <div id="discover" ref={this.discoverRef}>
            <CloudDiscover {...this.props} ></CloudDiscover>
        </div>
        <ImageDivider {...this.props} ></ImageDivider>
        <OurEnvironment></OurEnvironment>
        <ContactUs></ContactUs>
    </div>
    );
    }
    
}


export default WhatWeDo;    