import React from "react";
import { Container, Row, Col } from "reactstrap";
import { YmTextBlock } from "../../components/YmTextBlock";
import { BgColor } from "../../models/BgColor";
import TopNavigation from "../../components/TopNavigation";

import partnerJpg from "../../assets/img/partners/youmoni-partner.jpg";
import partnerWebp from "../../assets/img/partners/youmoni-partner.webp";

import ymLivingIot from "../../assets/img/living-app/Youmoni-living-app-iot-platform-servcies.png";
import ymWall from "../../assets/img/living-app/Youmoni-Small-data-garden-wall-sensor.png";
import ymOnline from "../../assets/img/living-app/Youmoni-Online-Apartment-Sensor-Package-2.png";
import ymSense from "../../assets/img/living-app/Youmoni-Space Invento-HV3.png";
import appScreenPng from "../../assets/img/smart-city/youmoni-app-screen.png";

import "./SmartCity.css";
import { HeaderImage, YmHeader } from "../../components/YmHeader";
import { ContactForm } from "../../Forms/ContactForm";
import { YmSection } from "../../components/YmSection";

const Header = (props: any): JSX.Element => {
  return (
    <div>
      <TopNavigation {...props} bgColor={BgColor.WHITE}></TopNavigation>
    </div>
  );
};

const TemperatureSection = () => {
  return (
    <>
      <Row>
        <Col>
          <YmTextBlock
            bgColor={BgColor.LIGHT}
            header="Temperature, air quality and humidity measurements"
            content=""
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={9}>
          <p>
            The platform can also be used to add smart IoT gateways in the
            property and measure e.g. temperature and humidity at selected
            places or even in all apartments. Other sensors can be added at any
            time, e.g. air quality, energy and or water usage. Data from the
            sensors will make energy control smarter and as a property owner you
            will save money
          </p>
        </Col>
        <Col xs={12} sm={3} className="p-0 justify-content-center">
          <div className="d-flex flex-column align-items-center my-auto h5 text-center">
            <img
              className="mb-3 container-sm col-sm-12 col-md-6 px-0 mx-auto"
              src={ymWall}
              alt="app-screen-png"
            ></img>
            <p>Air quality sensor</p>
          </div>
        </Col>
      </Row>
    </>
  );
};

const OnlineSection = () => {
  return (
    <>
      <Row>
        <Col>
          <YmTextBlock
            bgColor={BgColor.LIGHT}
            header="Online apartment"
            content={""}
          ></YmTextBlock>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={9}>
          <p>
            Youmoni has packaged an online apartment package. Each apartment
            gets a smart IoT gateway and a package of sensors, e.g. two-three
            wireless temperature and humidity sensors and a presence sensor. The
            packages can be complemented with optional upgrades with air
            quality, and energy measurements. Data from the apartments online
            will also make energy control smarter and as a property owner you
            will save money.
          </p>
        </Col>
        <Col xs={12} sm={3} className="p-0 justify-content-center">
          <div className="d-flex flex-column align-items-center my-auto h5 text-center">
            <img
              className="mb-3 container-sm col-sm-12 col-md-6 px-0 mx-auto"
              src={ymOnline}
              alt="Online-Apartment-png"
            ></img>
            <p>Apartment package</p>
          </div>
        </Col>
      </Row>
    </>
  );
};

const TextSection = () => {
  return (
    <Row>
      <Col xs={12} sm={9}>
        <YmTextBlock
          bgColor={BgColor.LIGHT}
          header="Smarter energy data and individual billing (IMD)"
          content={
            <>
              <p>
                Youmoni can deliver smart sensors that can gather data for
                individual billing of electrical energy and or cold/hot water.
                Either by tapping existing sensors from data or ad on current
                and or flow meeters that measures individual usage per
                apartment.
              </p>
            </>
          }
        ></YmTextBlock>
        <YmTextBlock
          bgColor={BgColor.LIGHT}
          header="Heat pumps, heat exchangers and coolers"
          content={
            <>
              <p>
                Youmoni Sense and DataPlug IoT Gateway have programs for
                measuring and logging heat pumps and coolers to be able to
                verify if it's efficient, need maintenance or tuning.
              </p>
              <p>
                The Youmoni platform can also measure, and log data related to
                energy usage from car charging and energy generated from solar
                panels.
              </p>
            </>
          }
        ></YmTextBlock>
      </Col>

      <Col xs={12} sm={3} className="p-0 justify-content-center">
        <div className="d-flex flex-column align-items-center my-auto h5 text-center">
          <img
            className="mb-3 container-sm col-sm-12 col-md-6 px-0 mx-auto"
            src={ymSense}
            alt="Sense"
          ></img>
          <p className="my-0 mx-2 text-left" style={{ maxWidth: 200 }}>
            Youmoni Sense Card for measuring heat pumps, AC coolers, heat
            exchangers. It can also control some functions.
          </p>
        </div>
      </Col>

      <Col xs={12} sm={9}>
        <YmTextBlock
          bgColor={BgColor.LIGHT}
          header="Save money – smarter energy data and machine learning"
          content={
            <>
              <p>
                All data collected from sensors or other data sources into the
                Youmoni IoT Platform can be processed and analyzed by a machine
                learning system that delivers predicative data for smarter
                energy control. The Youmoni IoT platform can deliver data to
                other platforms that control e.g. the heating system.
              </p>
            </>
          }
        ></YmTextBlock>
      </Col>

      <Col xs={12} sm={9}>
        <YmTextBlock
          bgColor={BgColor.LIGHT}
          header="Efficient operations - Service request"
          content={
            <>
              <p>
                Service requests from the Living App and the sensor platform can
                easily be integrated to external ticketing, field service and
                real estate management service systems, e.g. SAP, Service Now
                etc.
              </p>
            </>
          }
        ></YmTextBlock>
      </Col>
      <Col xs={12} sm={9}>
        <YmTextBlock
          bgColor={BgColor.LIGHT}
          header="Technical integration"
          content={
            <>
              <p>
                Youmoni hardware gateways and sensors platforms support Modbus,
                M-bus, integrates with analogue and digital sensors as well as
                PLCs and creates wireless sensors networks using Bluetooth, and
                or use existing LoRa or Wi-Fi networks.
              </p>
            </>
          }
        ></YmTextBlock>
      </Col>
    </Row>
  );
};

const ContactUs = () => {
  return (
    <YmSection
      bgColor={BgColor.PRIMARY}
      fluid={"xl"}
      name={"Contact"}
      header={`Don't hesitate to connect us in any matter regarding smart property or Living App`}
    >
      <Row>
        <Col sm="12" md="6" lg="6" className="p-0"></Col>
        <Col sm="12" md="6" className="p-0 order-1 order-md-2">
          <ContactForm> </ContactForm>
        </Col>
      </Row>
    </YmSection>
  );
};

const LivingApp: React.FC<{
  match: any;
  location: any;
  supportsWebp: boolean;
}> = (props) => {
  const partnerImage = props.supportsWebp ? partnerWebp : partnerJpg;

  return (
    <>
      <Header {...props}></Header>
      <YmHeader
        {...props}
        title="Youmoni Living App and smart property"
        media={<HeaderImage image={partnerImage}></HeaderImage>}
      ></YmHeader>

      <YmSection bgColor={BgColor.LIGHT} fluid={"xl"} name={""} header={""}>
        <Row>
          <Col>
            <h2 className="text-primary">
              Youmoni Living App and smart property
            </h2>
            <p>
              To realize the vision of a true smart property it's necessary to
              involve both the residents and smart sensor technology in an
              eco-system. Youmoni introduces Living App. It's an app platform
              for residents based upon an IoT platform that also connect smart
              sensors and smart property technology.
            </p>
            <p>
              With Youmoni Living App you can create a property specific channel
              withyour owny branding (white label) connecting the residents
              together by including social media, neighbor contacts, relevant
              individual smart features, optional concierge and booking
              services. The important thing is that the Living app becomes the
              main digital channel for the residents and the property. The app
              then becomes the window where we create visibility for more
              efficient service processes and interactions related to e.g.
              energy usage that really can affect everyone's economy and the
              impact of our common environment.
            </p>

            <h2 className="text-primary">
              Youmoni Living App services and IoT platform for properties
            </h2>
            <div className="text-center">
              <img
                className="mb-3 container-sm col-sm-12 col-md-6 px-0 mx-auto"
                src={ymLivingIot}
                alt="Living App"
              ></img>
            </div>
          </Col>
        </Row>
      </YmSection>

      <YmSection bgColor={BgColor.WHITE} fluid={"xl"} name={""} header={""}>
        <Row>
          <Col>
            <h2 className="text-primary">
              Index Residence implemented Living App
            </h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={9}>
            <div>
              <p>
                Youmoni Living App at Index Residence delivers housing society
                social media flows, content and concierge services with
                integrated digital services and sensor technology via one
                platform.
              </p>
              <p>
                One customer is Index Residence, a housing developer that
                delivers service oriented living environments. They have taken
                the next step in their digital offer and implemented Youmoni
                Living App.
              </p>
              The platform delivers a smart digital ecosystem for everyone
              stakeholders in a property including real estate dashboards,
              concierge staff who can handle social communication, digital
              service request. The app also includes booking features for co
              working spaces as well as car-, cycle- and boat pool.
              <p>
                Youmoni Living App also supports the journey towards their
                journey towards a smart property with even more digital services
                for residents such as Digital identity, Delivery boxes, Mobile
                payment, Car-pool with payment, and online apartments with
                sensors that provide smart data and functions for the resident,
                and data for more efficient heat or cooler control and energy
                management
              </p>
            </div>
          </Col>
          <Col xs={12} sm={3} className="p-0 justify-content-center">
            <div className="d-flex flex-column align-items-center my-auto h5 text-center">
              <img
                className="mb-3 container-sm col-sm-12 col-md-6 px-0 mx-auto"
                src={appScreenPng}
                alt="Sense"
              ></img>
              <p className="my-0"></p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {" "}
            <h2 className="text-primary">Start with sensors</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={9}>
            <div>
              <p>
                With Youmoni's IoT and Living App platform you don't need to
                choose. If you want to start with for example smart sensors for
                e.g. temperature, air and or humidity measurements in your
                property it works just fine. Later you can at any time add the
                Living App with social media, booking resources, and other
                digital services and smart features for your residents.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={3} className="p-0 justify-content-center"></Col>
        </Row>

        <TemperatureSection></TemperatureSection>
        <OnlineSection></OnlineSection>
        <TextSection></TextSection>
      </YmSection>

      <ContactUs></ContactUs>
    </>
  );
};

export default LivingApp;
