import React from 'react';
import Main from '../components/Main';
import { YmFooter } from '../components/YmFooter';

const LandingLayout: React.FunctionComponent<{}> = props =>
    <div>
        <Main>{props.children}</Main>
        <YmFooter></YmFooter>
    </div>

export default LandingLayout;