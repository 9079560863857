import React, { useEffect, useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import { ParagraphEnum } from "../../../enums/Career.enums";
import { YmHtmlViewer } from "../../../components/YmHtmlViewer";
import { BgColor } from "../../../models/BgColor";
import TopNavigation from "../../../components/TopNavigation";
import { YmImageBlock } from "../../../components/YmImageBlock";
import youmoniHiring from "../../../assets/img/career/youmoni_we_are_hiring.jpg";
import { YmSection } from "../../../components/YmSection";
import { ContactForm } from "../../../Forms/ContactForm";
import { careerPageContent } from "./constant";
import "./career.css";
import "../main.css";

const Header = (props: any): JSX.Element => {

  return <div>
    <TopNavigation {...props} bgColor={BgColor.WHITE}></TopNavigation>
    <section className="px-3 px-md-5">
      <Container fluid>
        <Row style={{ marginTop: "90px" }}>
          <Col className="px-0 pb-2 pb-md-0" sm="12">
            <YmImageBlock
              media={(
                <div className={"position-relative"}>
                  <img
                    src={youmoniHiring}
                    className="w-100"
                  ></img>
                </div>
              )}
            ></YmImageBlock>
          </Col>
        </Row>
      </Container>
    </section>
  </div>;
};

const CareerBody = (props: any): JSX.Element => {


  return (
    <section className="py-4 px-3 px-md-5">
      <Container className="main-body mt-5">
        <Row>
          <p className="display-4 text-primary font-weight-bold">About Us and working at Youmoni
          </p>
          <p className="h3 text-black font-weight-bold">
            We are a leading technology company known for our cutting-edge IoT Platform and
            solutions and industry expertise. We have an open and knowledge sharing culture and a mix
            of senior and junior tech staff. We premier own initiatives and seek personnel that has real
            interest in solving our customers challenges using our technology. As a developer we think
            that you can show that you have developed your own stuff in your free time and that you
            really are into development.
          </p>
          <p className="h3 text-black font-weight-bold">
            Finally, we want to extend our team with people that want to work with IoT and
            digitizing the real physical world. A personal interest and or knowledge acquired in
            other ways regarding environmental technology, vehicles, real estate, home
            automation, machines in industry or similar is positive. Together we have the
            possibility to really affect our customers efficiency, save energy and be part of the
            transformation towards a better environment and sustainability in general.
          </p>
        </Row>
        <Row>
        <Col xs={12} sm={9} className="px-0">
        <p className="h1 text-primary font-weight-bold">Current Job Openings</p>
          {careerPageContent.filter((section) => section?.id).map((section,index) => (
           <div key={`link_${index}`}><a href={`#${section.id}`} className="small">{section.title}</a></div>
          ))}
          </Col>
        </Row>
         
        {careerPageContent.map((section) => (
          <Row id={section.id} key={section.id}>
            <Col xs={12} sm={9} className="px-0">
              <p className="h1 text-primary font-weight-bold">{section.title}</p>
              {section.paragraphs && section.paragraphs.map((paragraph, index) => (
                <React.Fragment key={`${section.title}_${index}`}>
                  {paragraph.type === ParagraphEnum.text && paragraph.content &&
                    <p><YmHtmlViewer html={paragraph.content} /></p>}
                  {paragraph.type === ParagraphEnum.list && paragraph.listItems &&
                    <ul>
                      {paragraph.listItems.map((listItem) => (
                        <li key={listItem}><YmHtmlViewer html={listItem} /></li>))}
                    </ul>
                  }
                </React.Fragment>
              ))}
              {section.properties && section.properties.map((property) => (
                <Row key={property.propTitle}>
                  <Col xs={12} className="pl-4">
                    <p className="h3 text-primary font-weight-bold">{property.propTitle}</p>
                    {property.paragraphs && property.paragraphs.map((paragraph, index) => (
                      <React.Fragment key={`${property.propTitle}_${index}`}>
                        {paragraph.type === ParagraphEnum.text && paragraph.content &&
                          <p><YmHtmlViewer html={paragraph.content} /></p>}
                        {paragraph.type === ParagraphEnum.list && paragraph.listItems &&
                          <ul>
                            {paragraph.listItems.map((listItem) => (
                              <li key={listItem}><YmHtmlViewer html={listItem} /></li>))}
                          </ul>
                        }
                      </React.Fragment>
                    ))}
                  </Col>
                </Row>
              ))}
            </Col>
            <Col xs={12} sm={3} className="p-0 justify-content-center">
            </Col>
          </Row>
        ))}
      </Container>
    </section>
  );
};

const CareerSchedule = (props: any): JSX.Element => {
  return (
    <YmSection
      bgColor={BgColor.PRIMARY}
      fluid="xl"
      name=""
      header="Schedule a meeting with the Youmoni Team"
    >
      <Row>
        <Col sm="12" md="6" lg="6" className="p-0">
        </Col>
        <Col sm="12" md="6" className="p-0 order-1 order-md-2">
          <ContactForm></ContactForm>
        </Col>
      </Row>
    </YmSection>
  );
};

const softwareRef = React.createRef<HTMLDivElement>();
const hardwareRef = React.createRef<HTMLDivElement>();
const scrollInto = (location: any) => {
  if (location && location.hash) {
    let node: HTMLDivElement | null = null;
    switch (location.hash) {
      case "#software":
        node = softwareRef.current;
        break;
      case "#hardware":
        node = hardwareRef.current;
        break;
      default:
        break;
    }
    if (node) {
      node.scrollIntoView({ behavior: "smooth" });
    }
  }
};

const Index: React.FC<{ match: any, location: any }> = (props) => {

  scrollInto(props.location);

  return (<div className="products">
      <Header {...props}></Header>
      <CareerBody {...props}></CareerBody>
      <CareerSchedule></CareerSchedule>
    </div>
  );
};

export default Index;
