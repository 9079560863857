import React from 'react';
import { Container, Row, Col } from 'reactstrap';


import TopNavigation from '../../components/TopNavigation';
import { YmImageBlock } from '../../components/YmImageBlock';
import { BgColor } from '../../models/BgColor';
import { YmCollapseRow } from '../../components/YmCollapseRow';
import { YmTextBlock } from '../../components/YmTextBlock';
import { Link } from 'react-router-dom';

//SOLUTIONS IMAGES
import logisticsJpg from '../../assets/img/solutions/youmoni-logistics.jpg';
import logisticsWebp from '../../assets/img/solutions/youmoni-logistics.webp';

import consoleJpg from '../../assets/img/solutions/youmoni-logistics-collapse.png';
import consoleWebp from '../../assets/img/solutions/youmoni-logistics-collapse.webp';

import industryJpg from '../../assets/img/solutions/youmoni-industry.jpg';
import industryWebp from '../../assets/img/solutions/youmoni-industry.webp';
import functionalJpg from '../../assets/img/solutions/youmoni-functional.jpg';
import functionalWebp from '../../assets/img/solutions/youmoni-functional.webp';

import retailJpg from '../../assets/img/solutions/youmoni-automated-retail.jpg'
import retailWebp from '../../assets/img/solutions/youmoni-automated-retail.webp'

import industryDamJpg from '../../assets/img/solutions/youmoni-industry-dam.jpg';
import industryDamWebp from '../../assets/img/solutions/youmoni-industry-dam.webp';

import energyJpg from '../../assets/img/solutions/youmoni-energy.jpg';
import energyWebp from '../../assets/img/solutions/youmoni-energy.webp';

import smartcityJpg from '../../assets/img/solutions/youmoni-smart-city.jpg';
import smartcityWebp from '../../assets/img/solutions/youmoni-smart-city.webp';

import propertyJpg from '../../assets/img/solutions/youmoni-property.jpg';
import propertyWebp from '../../assets/img/solutions/youmoni-property.webp';

import customIotJpg from '../../assets/img/solutions/youmoni-custom-iot.png';
import customIotWebp from '../../assets/img/solutions/youmoni-custom-iot.webp';
import customIotHardwareJpg from '../../assets/img/solutions/youmoni-hardware.jpg';
import customIotHardwareWebp from '../../assets/img/solutions/youmoni-hardware.webp';

import trackntraceJpg from '../../assets/img/solutions/youmoni-track-n-trace.jpg';
import trackntraceWebp from '../../assets/img/solutions/youmoni-track-n-trace.webp';

import trackingJpg from '../../assets/img/solutions/youmoni-tracking.png';
import trackingWebp from '../../assets/img/solutions/youmoni-tracking.webp';

import remoteMonitoringJpg from '../../assets/img/solutions/youmoni-monitoring.jpg';
import remoteMonitoringWebp from '../../assets/img/solutions/youmoni-monitoring.webp';

import dataCollectionJpg from '../../assets/img/solutions/youmoni-datacollection.jpg';

import dataCollectionWebp from '../../assets/img/solutions/youmoni-datacollection.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { ContactUs } from '../../content/ContactUs';

const ListItem = (props:any) : JSX.Element => {
    const textClass = props.bgColor === 'primary' ? 'white' : ''
    const imgClass = props.bgColor === 'primary' ? 'white' : 'primary'
return <Row>
    <Col className="col-auto pl-3 pr-0"><FontAwesomeIcon className={`text-${imgClass}`} size={'xs'} icon={faCheck}></FontAwesomeIcon></Col>
    <Col className={`text-${textClass}`}>{props.text}</Col>
</Row>
}

const Header = (props: any): JSX.Element => {
    const industryImage = props.supportsWebp ? industryWebp : industryJpg;
    const functionalImage = props.supportsWebp ? functionalWebp : functionalJpg;
    return <div>
        <TopNavigation {...props} bgColor={BgColor.WHITE}></TopNavigation>
        <section className="px-3 px-md-5">
            <Container fluid={true}>
                <Row style={{ marginTop: '90px' }}>
                    <Col className="px-0 pr-md-3 pb-2 pb-md-0" sm="12" md="6">
                        <YmImageBlock
                            media={(<div className={'position-relative'} >
                                <div className={'cover-scroll'} style={{ height: '80vh', width: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: `url('${industryImage}')` }}></div>
                                <div className={'opacity-black'} style={{ height: '80vh', width: '100%', position: 'absolute', top: 0 }}></div>
                            </div>)}
                            header={<Link className="text-white" to="/solutions#industry">Industry Solutions</Link>}
                        ></YmImageBlock>
                    </Col>
                    <Col className="px-0 pl-md-3 pb-2 pb-md-0" sm="12" md="6">
                        <YmImageBlock
                            media={(
                                <div className={'position-relative'}>
                                    <div className={'cover-scroll'} style={{ height: '80vh', width: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: `url('${functionalImage}')` }}></div>
                                    <div className={'opacity-black'} style={{ height: '80vh', width: '100%', position: 'absolute', top: 0 }}></div>
                                </div>
                            )}
                            header={<Link className="text-white" to="/solutions#functional">Functional Solutions</Link>}
                        ></YmImageBlock>
                    </Col>
                </Row>
            </Container>
        </section>

    </div>
}

const Logistics = (props: any): JSX.Element => {
    const logisticsImage = props.supportsWebp ? logisticsWebp : logisticsJpg;
    const consoleImage = props.supportsWebp ? consoleWebp : consoleJpg;
    const capabilities = [
        {
            title: "Use Cases", list: ['Fleet management with sensors and integrated vehicle data', 'Container and asset tracking with complementary sensors', 'Food, medicine and sensitive goods delivery', 'Combine vehicle with asset tracking of goods onboard']
        },
        {
            title: "Cloud features", list: ['Ready to use cloud application', 'Vehicle, container or assets management', 'Tracking, routes and external sensor data monitoring', 'Notifications and business rules', 'Service history, product information and service manuals', 'Driver behavior and CO2 friendly driving']
        },
        {
            title: "IoT hardware", list: ['Flexible vehicle IoT GW with 2g/3g/4g connectivity with integrated GPS', 'CAN and Modbus integration with vehicle equipment and data', 'Wired or wireless sensors Trackers']
        },
        {
            title: "Sensors", list: ['Temp', 'Humidity', 'Acceleration', 'Gyro', 'Radar', 'Magnetic switch', 'Energy', 'Battery']
        }
    ]
    return <YmCollapseRow
        bgColor={BgColor.WHITE}
        header={"Transportation & Logistics - Safely delivering what’s inside"}
        ingress={
            <div>
                <p>Is your home delivered food kept chilled enough on its way to your home? Is the journey optimized from a time, energy or a CO2 consumption perspective? Is the medical equipment's cold chain broken, or have the boxes been bumped too hard en route? We track and optimize a large number of physical properties in a constant flow, making complex logistics vehicle and asset tracking processes highly efficient and sustainable.</p>
                <p>Youmoni delivers transportation and logistics solutions including fleet management with sensors for securing the actual goods onboard. We also deliver asset tracking and container online solutions.</p>
            </div>}
        content={
            <Container fluid>
                <Row>
                    <Col xs="12" className={'p-0'}>
                        <YmImageBlock
                            media={(
                                <div className="position-relative">
                                    <div className={'cover-fixed'} style={{ height: '50vh', width: '100%', backgroundImage: `url(${logisticsImage})` }}></div>
                                </div>
                            )}
                        ></YmImageBlock>
                    </Col>
                </Row>
            </Container>
        }
        collapseContent={
            <div>
                <section className="px-0 px-xl-5 pb-5">
                    <Container fluid="xl">
                        <Row>
                            <Col lg="12" xl="12" className="pb-md-3 pb-xl-5 ">
                                <div style={{ minHeight: '50vh', height: '100%', width: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', backgroundImage: `url('${consoleImage}')` }}></div>
                            </Col>
                            <Col lg="12" xl="12" className="px-3 px-md-5 px-xl-3">
                                <h2 className='text-primary mb-3 mb-lg-5'>Capabilities</h2>
                                <div>
                                    <Row>
                                        {
                                            capabilities.map((item, index) => {
                                                return <Col key={`capabilities_${index}`} sm="6" md="6" lg="6" xl="3" className="pr-sm-4 pr-xl-5 d-flex justify-content-between">
                                                    <YmTextBlock header={item.title}
                                                        content={<ul className={'list-unstyled'}>
                                                            {item.list.map((text, index) => {
                                                                return <li key={`listite_${index}`}><ListItem bgColor='white' text={text}></ListItem></li>
                                                            })
                                                            }
                                                        </ul>
                                                        }></YmTextBlock>
                                                </Col>
                                            })
                                        }
                                    </Row>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        }>
    </YmCollapseRow>
}

const Retail = (props: any): JSX.Element => {
    const retailImage = props.supportsWebp ? retailWebp : retailJpg;
    const capabilities = [
        { title: "Use Cases", list: ['Remote cabinets online', 'Customers can pay for and pick and drop products', 'Vendor managed inventory on remote locations', 'Remote inventory online', 'Connected vending machines', 'Pick up boxes'] },
        { title: "Cloud features", list: ['Ready to use cloud application', 'Customer identification via apps', 'Bank ID', 'ID cards etc', 'E-business payments', 'ERP integration or B2C with Credit Cards, Paypal, Swish', 'Automated pick and drop calculations based on sensor data', 'Inventory state online', 'Automated replenishment orders'] },
        { title: "IoT hardware", list: ['Flexible IoT GW with 2g/3g/4g connectivity', 'Youmoni inventory sensors', 'Wired or wireless sensors'] },
        { title: "Sensors", list: ['Temp', 'Humidity', 'Battery', 'RFID', 'Youmoni shelf sensors', 'Cameras'] },
    ]
    return <YmCollapseRow
        bgColor={BgColor.LIGHT}
        header={"Automated Retail - Cabinets and shelves online"}
        ingress={<div>
            <p>What if you could place your products and items closer to where your customers consume them and combine that with e-commerce? More and more customers want instant delivery of the products after purchase. The Youmoni automated retail solution makes it easy to bring existing or new remote or vendor managed inventories online. The Youmoni IoT cloud and sensors can automatically manage user and end customer identification, manage locks, sense pick-and-drop and manage payment transactions.</p>
        </div>}
        content={<Container fluid>
            <Row>
                <Col xs="12" className={'p-0'}>
                    <YmImageBlock
                        media={(
                            <div className="position-relative">
                                <div className={'cover-fixed'} style={{ height: '50vh', width: '100%', backgroundImage: `url('${retailImage}')` }}></div>
                            </div>
                        )}
                    ></YmImageBlock>
                </Col>
            </Row>
        </Container>
        }
        collapseContent={
            <section className="px-3 px-md-5 pb-5">
                <Container fluid="xl" className={'p-0'}>
                    <Row>
                        <Col sm="12" lg="12" xl="6" >
                            <h2 className='text-primary mb-3 mb-lg-5'>Capabilities</h2>
                        </Col>
                    </Row>
                    <Row className="px-0">
                        {
                            capabilities.map((item, index) => {
                                return <Col key={`capabilities_${index}`} xs="12" sm="12" md="6" lg="6" xl="3" className={`pr-sm-4 pr-xl-5 d-flex justify-content-between`}>
                                    <YmTextBlock header={item.title}
                                        content={<ul className={'list-unstyled'}>
                                            {item.list.map((text, index) => {
                                                return <li key={`listite_${index}`}><ListItem text={text}></ListItem></li>
                                            })
                                            }
                                        </ul>
                                        }></YmTextBlock>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        }
    >
    </YmCollapseRow>
}

const Industry = (props: any): JSX.Element => {
    const industryImage = props.supportsWebp ? industryDamWebp : industryDamJpg;
    const capabilities = [
        {
            title: "Use Cases", list: ["Remote machines and assets online",
                "Diesel generators and electric motor applications monitoring",
                "Monitor machine health, energy consumption, levels, temperatures, digital I/O data",
                "Air quality and gas detection from workshops, industrial processes and mining",
                "Control start and stop and or levels.",
                "Minimize manual service checks",
                "Prevent machine breakdowns and stops",
                "Predictive maintenance"]
        },
        {
            title: "Cloud features", list: ["Ready to use cloud base application",
                "Data objects for engines, generators and engine CAN data",
                "Tank level metering",
                "Vibration, temp, pressure sensor data and general machine health monitoring"]
        },
        {
            title: "IoT hardware", list: ["Flexible IoT GW with 2g/3g/4g connectivity with or without GPS tracking",
                "Wired or wireless sensors",
                "Digital I/O data integration from RS232, RS485 and CAN / Ethernet networks"]
        },
        {
            title: "Sensors", list: ["Many sensors integrated for various applications",
                "Temp", "humidity", "vibration", "pressure", "level", "GPS", "digital I/O converters and more"
            ]
        },
    ]
    return <YmCollapseRow
        bgColor={BgColor.PRIMARY}
        header={"Industry"}
        ingress={<div>
            <p>Within the industry and utility sectors, many machines and assets are left alone at remote locations with either operational problems, or expensive service processes. With remote monitoring of assets via Youmoni management and predictive maintenance solutions, remotely located equipment is no longer a problem. You will gain better control over your equipment and machinery, but also increase energy-efficiency and productivity, together with reduced operational costs by minimized manual remote service checks. The Youmoni Cloud will monitor and visualize asset status, and detects changes in performance or thresholds. Assets can also be remotely managed.</p>
        </div>}
        content={
            <Container fluid>
                <Row>
                    <Col xs="12" className={'p-0'}>
                        <YmImageBlock
                            media={(
                                <div className="position-relative">
                                    <div className={'cover-fixed'} style={{ height: '50vh', width: '100%', backgroundImage: `url(${industryImage})` }}></div>
                                </div>
                            )}
                        ></YmImageBlock>
                    </Col>
                </Row>
            </Container>
        }
        collapseContent={
            <section className="px-3 px-md-5 pb-5">
                <Container fluid="xl" className="px-0">
                    <Row>
                        <Col sm="12" lg="12" xl="6">
                            <h2 className='text-white mb-3 mb-lg-5'>Capabilities</h2>
                        </Col>
                    </Row>
                    <Row>
                        {
                            capabilities.map((item, index) => {
                                return <Col key={`capabilities_${index}`} sm="12" md="6" xl="3" className={`pr-0 pr-md-6 mb-2 mb-md-5`}>
                                    <YmTextBlock bgColor={BgColor.PRIMARY} header={item.title}
                                        content={<ul className={'list-unstyled'}>
                                            {item.list.map((text, index) => {
                                                return <li key={`listite_${index}`}><ListItem bgColor='primary' text={text}></ListItem></li>
                                            })
                                            }
                                        </ul>
                                        }></YmTextBlock>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        }>
    </YmCollapseRow>
}

const Energy = (props: any): JSX.Element => {
    const energyImg = props.supportsWebp ? energyWebp : energyJpg;
    const capabilities = [
        {
            title: "Use Cases", list: ["Monitoring remote utility stations, status, health, intrusion detection etc",
                "New and older transformers health, voltage, oil temp, H2, status, oil ditch level",
                "Water cleaners status, drinking water quality, flow and water pressure",
                "Minimize manual service checks",
                "Prevent machine breakdowns and stops",
                "Predictive maintenance"]
        },
        {
            title: "Cloud features", list: ["Ready to use cloud applications.",
                "Data objects for transformers and water cleaners",
                "Vibration, temp, pressure sensor data and general machine health monitoring",
                "Big data analysis",
                "Business and SCADA integration",
            ]
        },
        {
            title: "IoT hardware", list: ["Flexible IoT GW with 2g/3g/4g connectivity with or without gps tracking",
                "Wired or wireless sensors",
                "Digital I/O data integration from RS232, RS485 and CAN / Ethernet networks"]
        },
        {
            title: "Sensors", list: ["Many sensors integrated for various applications",
                "Temp, humidity, voltage, current, vibration, pressure, GPS and digital I/O, analogue 4-20mA status from analogue equipment"]
        },
    ]
    return <YmCollapseRow
        bgColor={BgColor.WHITE}
        header={"Energy / Utility"}
        ingress={<div>
            <p>
                We deliver solutions for monitoring of solar panels, transformer stations, fuel engines, electric motors in various applications and tank levels. Our smart city air quality stations are also useful to monitor industrial processes like burning fossil fuels, oil mist in workshops, gases from chemical processing and particles from drilling in tunnels and mines. It's important to be able to detect particles (PM 2.5, PM 10) and toxic gases like CO, CO2, SO2, H2 and many others, which create bad and dangerous work environments. Active air and gas monitoring can prevent many accidents.
            </p>
        </div>}
        content={
            <Container fluid>
                <Row>
                    <Col xs="12" className={'p-0'}>
                        <YmImageBlock
                            media={(
                                <div className="position-relative">
                                    <div className={'cover-fixed'} style={{ height: '50vh', width: '100%', backgroundImage: `url(${energyImg})` }}></div>
                                </div>
                            )}
                        ></YmImageBlock>
                    </Col>
                </Row>
            </Container>
        }
        collapseContent={
            <section className="px-3 px-md-5 pb-5">
                <Container fluid="xl" className="px-0">
                    <Row>
                        <Col sm="12" lg="6" className="">
                            <h2 className='text-primary mb-3 mb-lg-5'>Capabilities</h2>
                        </Col>
                    </Row>
                    <Row>
                        {
                            capabilities.map((item, index) => {
                                return <Col key={`capabilities_${index}`} sm="12" md="6" lg="3" className="pr-0 pr-md-6 mb-2 mb-md-5">
                                    <YmTextBlock header={item.title}
                                        content={<ul className={'list-unstyled'}>
                                            {item.list.map((text, index) => {
                                                  return <li key={`listite_${index}`}><ListItem bgColor='white' text={text}></ListItem></li>
                                            })
                                            }
                                        </ul>
                                        }></YmTextBlock>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        }>
    </YmCollapseRow>
}

const SmartCity = (props: any): JSX.Element => {
    const smartcityImage = props.supportsWebp ? smartcityWebp : smartcityJpg;
    const capabilities = [
        {
            title: "Use Cases", list: ["District heating pipe and heat exchanger monitoring",
                "Water supply and quality monitoring",
                "Air quality and environment measurement stations",
                "Local electric grid and smart energy metering",
                "Waste management",
                "Asset tracking"]
        },
        {
            title: "Cloud features", list: ["Ready to use cloud remote monitoring applications",
                "Custom adaptations of user interfaces and integration to city and SCADA systems",
                "Citizens apps empowering engagement and local insights of our city's quality"]
        },
        {
            title: "IoT hardware", list: ["Several IoT gateways with 2g/3g/4g & LoRa connectivity with or without GPS",
                "Using open IoT electronics for cheap distribution",
                "Environmental station package for smart cities",
                "Asset trackers for CO2 calculations of routes "]
        },
        {
            title: "Sensors", list: ["Many sensors integrated for various applications",
                "Temperature, flow, pressure, sound",
                "Environment station: Temp, humidity, PM 2.5, PM10, CO2, NOX, Noise",
                "Water quality station: TDS sensors, flow and pressure",
                "Waste management: Waste levels with radar and or ultrasound"]
        },
    ]
    return <YmCollapseRow
        bgColor={BgColor.LIGHT}
        header={"Smart City"}
        ingress={<div>
            <p>Smart cities will gather data from everywhere to better understand how different scenarios affect the efficiency of the city and how it's affecting our common environment. Youmoni has developed and tested solutions that focus on the environment, meaning air and water quality, waste levels and energy use. Our property also goes hand in hand with the smart city. Environmental sensors will give insight in how we affect the environment and how changes make things better or worse. The overall objective is to improve the quality of citizens' lifestyle and accelerates economic growth. Our smart city solutions help public and private sector planners to promote efficient use of resources and sustainability, drive citizen engagement, and address community challenges.</p>
        </div>}
        content={
            <Container fluid>
                <Row>
                    <Col xs="12" className={'p-0'}>
                        <YmImageBlock
                            media={(
                                <div className="position-relative">
                                    <div className={'cover-fixed'} style={{ height: '50vh', width: '100%', backgroundImage: `url(${smartcityImage})` }}></div>
                                </div>
                            )}
                        ></YmImageBlock>
                    </Col>
                </Row>
            </Container>
        }
        collapseContent={
            <section className="px-3 px-md-5 pb-5">
                <Container fluid="xl" className="px-0">
                    <Row>
                        <Col sm="12" lg="6" className="">
                            <h2 className='text-primary mb-3 mb-lg-5'>Capabilities</h2>
                        </Col>
                    </Row>
                    <Row>
                        {
                            capabilities.map((item, index) => {
                                return <Col key={`capabilities_${index}`} sm="6" md="6" lg="3" className="pr-0 pr-md-6 mb-2 mb-md-5">
                                    <YmTextBlock bgColor={BgColor.LIGHT} header={item.title}
                                        content={<ul className={'list-unstyled'}>
                                            {item.list.map((text, index) => {
                                                return <li key={`listite_${index}`}><ListItem bgColor='white' text={text}></ListItem></li>
                                            })
                                            }
                                        </ul>
                                        }></YmTextBlock>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        }>
    </YmCollapseRow>
}

const Property = (props: any): JSX.Element => {
    const propertyImage = props.supportsWebp ? propertyWebp : propertyJpg;
    const capabilities = [
        {
            title: "Use Cases", list: ["Optimize ventilation and filter measurement",
                "Smart energy management, balance heating, cooling and ventilation",
                "Air and water quality measurement",
                "Local electric grid balance - solar panels and electric car charging",
                "Waste management"]
        },
        {
            title: "Cloud features", list: ["Ready to use cloud remote monitoring and sensor applications",
                "Custom adaptations of user interfaces and integration to property system",
                "Apps framework for renter, enabling new digital services and empowering engagement",
                "External weather data"]
        },
        {
            title: "IoT hardware", list: ["Several IoT gateways with 2g/3g/4g & LoRa connectivity with or without GPS",
                "Digital I/O gateways e.g Modbus",
                "Electronic locks, air quality stations and sensors, ventilation sensors",
                "Ventilation and heating control mechanisms"]
        },
        {
            title: "Sensors", list: ["Many sensors integrated for various applications",
                "Air quality, temp, humidity, PM 2.5, PM10, ventilation air pressure, water flow",
                "Local grid energy meters, energy distribution sensors",
                "Waste levels with radar and or ultrasound"]
        },
    ]
    return <YmCollapseRow
        bgColor={BgColor.PRIMARY}
        header={"Property"}
        ingress={<div>
            <p>Clean air, clean water, heating and cooling, smart energy.</p>
            <p>To be able to optimize for the environment, meet new energy regulations, optimize operations and engage with your customers you need to digitize your property and measure everything. Collect data from every area – from lighting levels, energy and effects in electric distribution boards, ventilation and air quality, heating and cooling systems, signage, and get the visibility you need on temperature, humidity, airflow, air quality (PM) and e.g. water leaks. The important thing is to be able to aggregate all data regardless of manufacturer. </p>
            <p>With Youmoni cloud service, property sensors, data aggregation and external data you can get an overview of everything, optimize service operations, and start to analyze the whole ecosystem and improve energy consumption, balance e.g. solar power and electric car charging and improve air quality. With big data and intelligent analytics, we can recognize huge potential for energy savings, suggest effects balance improvements and improve temperature stability and air quality.</p>
        </div>}
        content={
            <Container fluid>
                <Row>
                    <Col xs="12" className={'p-0'}>
                        <YmImageBlock
                            media={(
                                <div className="position-relative">
                                    <div className={'cover-fixed'} style={{ height: '50vh', width: '100%', backgroundImage: `url(${propertyImage})` }}></div>
                                </div>
                            )}
                        ></YmImageBlock>
                    </Col>
                </Row>
            </Container>
        }
        collapseContent={
            <div>
                <section className="px-3 px-md-5 pb-5">
                    <Container fluid="xl" className="px-0">
                        <Row>
                            <Col sm="12" lg="6" className="">
                                <h2 className='text-white mb-3 mb-lg-5'>Capabilities</h2>
                            </Col>
                        </Row>
                        <Row>
                            {
                                capabilities.map((item, index) => {
                                    return <Col key={`capabilities_${index}`} sm="6" md="6" lg="3" className="pr-0 pr-md-6 mb-2 mb-md-5">
                                        <YmTextBlock bgColor={BgColor.PRIMARY} header={item.title}
                                            content={<ul className={'list-unstyled'}>
                                                {item.list.map((text, index) => {
                                                    return <li key={`listite_${index}`}><ListItem bgColor='primary' text={text}></ListItem></li>
                                                })
                                                }
                                            </ul>
                                            }></YmTextBlock>
                                    </Col>
                                })
                            }
                        </Row>
                    </Container>
                </section>
            </div>
        }>
    </YmCollapseRow>
}


//Functional
const CustomIoT = (props: any): JSX.Element => {
    const customIotImage = props.supportsWebp ? customIotWebp : customIotJpg;
    const customIotHardwareImage = props.supportsWebp ? customIotHardwareWebp : customIotHardwareJpg;
    const capabilities = [
        {
            title: "Use Cases", list: ["Custom design of mechanics and electronics that bring your product online",
                "Specific apps and business processes are cost effectively developed using the ready to go services in our cloud product suite",
                "Custom integration to back end systems"]
        },
        {
            title: "Cloud features", list: ["Ready to go IoT cloud services enables your custom IoT solution", "Brand everything according to your company's look and feel",
                "White label the solution to your end customers"
            ]
        },
        {
            title: "IoT hardware", list: ["Use your own or Youmonis IoT gateways with 2g/3g/4g & LoRa connectivity Digital I/O gateways e.g Modbus",
                "Custom design hardware and connectivity"]
        },
        {
            title: "Sensors", list: ["Use your products digital I/O",
                "Add 3rd party sensors as needed"]
        },
    ]
    return <YmCollapseRow
        bgColor={BgColor.WHITE}
        header={"Custom IoT Solution"}
        ingress={<div>
            <p>
                With the Youmoni IoT product suite from ready to use cloud and integrated IoT gateways, contracted connectivity and sensors more or less any IoT solution can be implemented quickly and cost efficient. Dashboards and apps are quickly adapted with standardized React Ui components and things will be online in days using our a standardized gateways or prototyping hardware based on Raspberry or Arduino. We also custom design IoT hardware and manufacture prototypes. Our mechanical design engineers will draw 3d case designs that will be 3D printed in our lab.
            </p>
        </div>}
        content={
            <Container fluid>
                <Row>
                    <Col xs="12" className={'p-0'}>
                        <YmImageBlock
                            media={(
                                <div className="position-relative">
                                    <div className={'cover-fixed'} style={{ height: '50vh', width: '100%', backgroundImage: `url(${customIotHardwareImage})` }}></div>
                                </div>
                            )}
                        ></YmImageBlock>
                    </Col>
                </Row>
            </Container>
        }
        collapseContent={
            <div>
                <section className="px-3 px-md-5 pb-5">
                    <Container fluid="xl" className="px-0">
                        <Row>
                            <Col xs="12" className={"mb-4"}>
                                <YmImageBlock
                                    media={(
                                        <div className="position-relative">
                                            <div style={{ height: '50vh', backgroundSize: "contain", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: '100%', backgroundImage: `url(${customIotImage})` }}></div>
                                        </div>
                                    )}
                                ></YmImageBlock>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" lg="6" className="">
                                <h2 className='text-primary mb-3 mb-lg-5'>Capabilities</h2>
                            </Col>
                        </Row>
                        <Row>
                            {
                                capabilities.map((item, index) => {
                                    return <Col key={`capabilities_${index}`} sm="6" md="6" lg="3" className="pr-0 pr-md-6 mb-2 mb-md-5">
                                        <YmTextBlock bgColor={BgColor.WHITE} header={item.title}
                                            content={<ul className={'list-unstyled'}>
                                                {item.list.map((text, index) => {
                                                    return <li key={`listite_${index}`}><ListItem bgColor='white' text={text}></ListItem></li>
                                                })
                                                }
                                            </ul>
                                            }></YmTextBlock>
                                    </Col>
                                })
                            }
                        </Row>
                    </Container>
                </section>
            </div>
        }>
    </YmCollapseRow>
}
const TracknTrace = (props: any): JSX.Element => {
    const trackntraceImage = props.supportsWebp ? trackntraceWebp : trackntraceJpg;
    const trackingImage = props.supportsWebp ? trackingWebp : trackingJpg;
    const capabilities = [
        {
            title: "Use Cases", list: ["Vehicle tracking",
                "Route optimization for saving mileage and minimize CO2",
                "Industrial assets tracking",
                "Container and packages",
                "Personal security and select nearest for personal assistance or support",]
        },
        {
            title: "Cloud features", list: ["Ready to go IoT cloud tracking services enables your custom IoT solution",
                "Ready to use tracking console, apps and portal",
                "Brand everything according to your company's look and feel",
                "White label the solution to your end customers"]
        },
        {
            title: "IoT hardware", list: ["Use your own trackers or geo positioning data",
                "Youmonis IoT gateways with 2g/3g/4g & LoRa, Satellite and GPS",
                "Youmoni asset tracking hardware from several partners",
                "Custom design hardware and connectivity"]
        },
        {
            title: "Sensors", list: ["Wireless temperature sensors",
                "Wireless sensor nodes that can integrate sensors to trackers"]
        },
    ]
    return <YmCollapseRow
        bgColor={BgColor.LIGHT}
        header={"Track n’ trace"}
        ingress={<div>
            <p>Efficient track and trace processes empower logistics systems and teams to identify, monitor and locate items in real time, enabling superior business intelligence and faster responses to issues.</p>
            <p>Real time position of assets, vehicles, containers, and people affect many business processes and with Youmoni track n trace solutions these positions can be integrated into your business processes. Use Youmoni cloud and select suitable tracking hardware depending on asset and use case to track anything. Some trackers also give other important process data like acceleration and temperature and humidity from internal and external sensors</p>
            <p>Youmoni tacking hardware comes from different vendors and are either powered by an internal battery or external power source. We have trackers using 2g/3g/4g and new standards like 4g CAT M1 and free LoRA networks. Trackers that can switch to satellite in remote areas are also available.</p>
            <p>Trackers with internal battery can last for up to 10 years. The smallest gps trackers are only 45 mm and weighs 27 grams.</p>
        </div>}
        content={
            <Container fluid>
                <Row>
                    <Col xs="12" className={'p-0'}>
                        <YmImageBlock
                            media={(
                                <div className="position-relative">
                                    <div className={'cover-fixed'} style={{ height: '50vh', width: '100%', backgroundImage: `url(${trackntraceImage})` }}></div>
                                </div>
                            )}
                        ></YmImageBlock>
                    </Col>
                </Row>
            </Container>
        }
        collapseContent={
            <div>
                <section className="px-0 px-xl-5 pb-5">
                    <Container fluid="xl">
                        <Row>
                            <Col lg="12" xl="6" className="px-0 pr-0 pr-xl-5 pb-5 pb-xl-0 ">
                                <div style={{ minHeight: '50vh', height: '100%', width: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', backgroundImage: `url('${trackingImage}')` }}></div>
                            </Col>
                            <Col lg="12" xl="6" className="px-3 px-md-5 px-xl-3">
                                <h2 className='text-primary mb-3 mb-lg-5'>Capabilities</h2>
                                <div>
                                    <Row>
                                        {
                                            capabilities.map((item, index) => {
                                                return <Col key={`capabilities_${index}`} sm="6" className="mb-4">
                                                    <YmTextBlock bgColor={BgColor.LIGHT} header={item.title}
                                                        content={<ul className={'list-unstyled'}>
                                                            {item.list.map((text, index) => {
                                                                return <li key={`listite_${index}`}><ListItem bgColor='white' text={text}></ListItem></li>
                                                            })
                                                            }
                                                        </ul>
                                                        }></YmTextBlock>
                                                </Col>
                                            })
                                        }
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        }>
    </YmCollapseRow>
}

const RemoteMonitoring = (props: any): JSX.Element => {
    const remoteMonitoringImage = props.supportsWebp ? remoteMonitoringWebp : remoteMonitoringJpg;
    const capabilities = [
        {
            title: "Use Cases", list: ["Remote tank-, ditch-, waste-, well levels",
                "Pipelines, water pipes, heat water pipes",
                "Pumps, fans",
                "Heat exchangers",
                "Electrical installations and batteries"]
        },
        {
            title: "Cloud features", list: ["Ready to go IoT cloud with monitoring services",
                "Ready to use monitoring console, apps and portal",
                "Data analysis and notifications"]
        },
        {
            title: "IoT hardware", list: ["Youmonis IoT gateways and trackers with 2g/3g/4g & LoRa, Satellite and GPS",
                "Youmoni asset tracking hardware from several partners",
                "Custom design hardware and connectivity"]
        },
        {
            title: "Sensors", list: ["Wireless and wired sensors",
                "Special sensors for levels, pipe, flow and leakage monitoring"]
        },
    ]
    return <YmCollapseRow
        bgColor={BgColor.PRIMARY}
        header={"Remote Monitoring - don't drive around and check things  "}
        ingress={<div>
            <p>Always on the move and spend a lot of your organizations operational efforts and money on checking remote assets or processes? You may have asset on construction sites, at remote utility stations, lease equipment to other parties that you want to check, refill and or just know the location and or status of. </p>
            <p>We could more or less bring any remote equipment, tank, vehicle or process online so pls talk to our experts how we could optimize your business and save you money. You could even save insurance money by bringing your asset online.  </p>
        </div>}
        content={
            <Container fluid>
                <Row>
                    <Col xs="12" className={'p-0'}>
                        <YmImageBlock
                            media={(
                                <div className="position-relative">
                                    <div className={'cover-fixed'} style={{ height: '50vh', width: '100%', backgroundImage: `url(${remoteMonitoringImage})` }}></div>
                                </div>
                            )}
                        ></YmImageBlock>
                    </Col>
                </Row>
            </Container>
        }
        collapseContent={
            <div>
                <section className="px-3 px-lg-5 pb-5">
                    <Container fluid="xl" className="px-0">
                        <Row>
                            <Col sm="12" lg="6" className="">
                                <h2 className='text-white mb-3 mb-lg-5'>Capabilities</h2>
                            </Col>
                        </Row>
                        <Row>
                            {
                                capabilities.map((item, index) => {
                                    return <Col key={`capabilities_${index}`} sm="6" md="6" lg="3" className="pr-0 pr-md-6 mb-2 mb-md-5">
                                        <YmTextBlock bgColor={BgColor.PRIMARY} header={item.title}
                                            content={<ul className={'list-unstyled'}>
                                                {item.list.map((text, index) => {
                                                    return <li key={`listite_${index}`}><ListItem bgColor='primary' text={text}></ListItem></li>
                                                })
                                                }
                                            </ul>
                                            }></YmTextBlock>
                                    </Col>
                                })
                            }
                        </Row>
                    </Container>
                </section>
            </div>
        }>
    </YmCollapseRow>
}

const DataCollection = (props: any): JSX.Element => {
    const dataCollectionImage = props.supportsWebp ? dataCollectionWebp : dataCollectionJpg;
    const capabilities = [
        {
            title: "Use Cases", list: ["Gather live data from your existing or new product series",
                "Use Youmoni as data collection platform for live or synced data"]
        },
        {
            title: "Cloud features", list: ["Use Youmoni cloud data platform for  your custom IoT solution",
                "Store data in Youmoni time series data for later analysis",
                "Integrate live data with your business systems"]
        },
        { title: "IoT hardware", list: ["Your own hardware or complement with Youmonis offerings"] },

    ]
    return <YmCollapseRow
        bgColor={BgColor.WHITE}
        header={"Data Collection - get new business insights"}
        ingress={<div>
            <p>Your business probably has asset management, ERP systems and data lakes or data warehouses where all data about your things properties are stored and analyzed according to your best practices. If your have not invested in bring your assets online and gather live data you only have the data registered and not real time and real-world data of their current status and health. Analysis models and data lakes may miss data and historical data in time series that could be a missing piece for total insights to be able to improve or transform your business process. </p>
            <p>In these scenarios you could use Youmoni hardware and cloud to collect live or synced data and store the data in time series and integrate with your current system. </p>
            <p>In short use the Youmoni product suite to collect data from your products and feed your existing systems and data models with data from the field.  </p>
        </div>}
        content={
            <Container fluid>
                <Row>
                    <Col xs="12" className={'p-0'}>
                        <YmImageBlock
                            media={(
                                <div className="position-relative">
                                    <div className={'cover-fixed'} style={{ height: '50vh', width: '100%', backgroundImage: `url(${dataCollectionImage})` }}></div>
                                </div>
                            )}
                        ></YmImageBlock>
                    </Col>
                </Row>
            </Container>
        }
        collapseContent={
            <div>
                <section className="px-3 px-md-5 pb-5">
                    <Container fluid="xl" className="px-0">
                        <Row>
                            <Col sm="12" lg="6" className="">
                                <h2 className='text-primary mb-3 mb-lg-5'>Capabilities</h2>
                            </Col>
                        </Row>
                        <Row>
                            {
                                capabilities.map((item, index) => {
                                    return <Col key={`capabilities_${index}`} sm="6" md="6" lg="3" className="pr-0 pr-md-6 mb-2 mb-md-5">
                                        <YmTextBlock bgColor={BgColor.WHITE} header={item.title}
                                            content={<ul className={'list-unstyled'}>
                                                {item.list.map((text, index) => {
                                                   return <li key={`listite_${index}`}><ListItem bgColor='white' text={text}></ListItem></li>
                                                })
                                                }
                                            </ul>
                                            }></YmTextBlock>
                                    </Col>
                                })
                            }
                        </Row>
                    </Container>
                </section>
            </div>
        }>
    </YmCollapseRow>
}

const industryRef = React.createRef<HTMLDivElement>();
const functionalRef = React.createRef<HTMLDivElement>();
const scrollInto = (location: any) => {
    if (location && location.hash) {
        let node: HTMLDivElement | null = null;
        switch (location.hash) {
            case '#industry':
                node = industryRef.current
                break;
            case '#functional':
                node = functionalRef.current
                break;
            default:
                break;
        }
        if (node) {
            node.scrollIntoView({ behavior: 'smooth' });
        }
    }
}
const Solutions: React.FC<{ match: any, location: any }> = (props) => {

    scrollInto(props.location);

    return (<div className="solutions">
        <Header {...props}></Header>
        <div ref={industryRef}>
            <Logistics {...props} />
            <Retail {...props}></Retail>
            <Industry {...props}></Industry>
            <Energy {...props}></Energy>
            <SmartCity {...props}></SmartCity>
            <Property {...props}></Property>
        </div>
        <div ref={functionalRef}>
            <CustomIoT {...props}></CustomIoT>
            <TracknTrace {...props}></TracknTrace>
            <RemoteMonitoring {...props}></RemoteMonitoring>
            <DataCollection {...props}></DataCollection>
        </div>
        <div>
            <ContactUs></ContactUs>
        </div>

    </div>
    );
}
export default Solutions;    