import React, { ReactNode } from 'react';

export interface YmImageBlockProps {
    media: ReactNode,
    header?: ReactNode
    content?: ReactNode,
}

export const YmImageBlock: React.FunctionComponent<YmImageBlockProps> = props => {

    const { media, header, content } = props;

    return <div>
        {media}
        <div className="absolute-center">
            {
                header &&
                <h1 className={'text-white text-center'}>{header}</h1>
            }
            {content &&
                <div className={'text-white text-center'}>
                    {content}
                </div>
            }
        </div>
    </div>
}
export default YmImageBlock;