import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import { YmHeader, HeaderImage } from '../../components/YmHeader';
import { YmCollapseRow } from '../../components/YmCollapseRow';
import { BgColor } from '../../models/BgColor';
import { YmImageBlock } from '../../components/YmImageBlock';
import { YmTextBlock } from '../../components/YmTextBlock';

import expertise1Jpg from '../../assets/img/expertise/youmoni-expertise-1.jpg';
import expertise2Jpg from '../../assets/img/expertise/youmoni-expertise-2.jpg';
import fasttrackJpg from '../../assets/img/expertise/youmoni-fast-track.png';
import expertise1Webp from '../../assets/img/expertise/youmoni-expertise-1.webp';
import expertise2Webp from '../../assets/img/expertise/youmoni-expertise-2.webp';
import fasttrackWebp from '../../assets/img/expertise/youmoni-fast-track.webp';

import managedIoTJpg from '../../assets/img/expertise/youmoni-managed-iot.jpg';
import managedIoTWebp from '../../assets/img/expertise/youmoni-managed-iot.webp';
import { ContactUs } from '../../content/ContactUs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const ListItem = (props:any) : JSX.Element => {
    const textClass = props.bgColor === 'primary' ? 'white' : ''
    const imgClass = props.bgColor === 'primary' ? 'white' : 'primary'
return <Row>
    <Col className="col-auto pl-3 pr-0"><FontAwesomeIcon className={`text-${imgClass}`} size={'xs'} icon={faCheck}></FontAwesomeIcon></Col>
    <Col className={`text-${textClass}`}>{props.text}</Col>
</Row>
}

const IntroSection = (): JSX.Element => {
    return <YmCollapseRow
        bgColor={BgColor.LIGHT}
        name={"Expertise"}
        header={"Expertise"}
        ingress={<div>
            <p>The digital revolution is ongoing. Real business transformation and new disruptive business models will be realized when the physical world is included in the digitization process – when things become online and are integrated into the digital value chain.</p>
            <p>Our experienced IoT team and partners have the expertise needed to transform your business needed to become a leader in your business vertical. When your things come online, new opportunities to save costs, find new operational models and create revenue streams will emerge.</p>
            <p>A IoT team needs management consultants, cloud experts, user interface designers, embedded programming, electronic and mechanical engineers. Together with our partners we can set up and deliver the neccessary expert team that, with the integrated product suite and methodology, quickly can move your IoT project forward.</p>
        </div>}
    ></YmCollapseRow>
}

const ExpertiseSection = (): JSX.Element => {
    return <YmCollapseRow
        bgColor={BgColor.WHITE}
        name={""}
        header={"Youmoni Expertise"}
        ingress={<div>
            <ul className={'list-unstyled'}>
                <li><ListItem bgColor='white' text="IoT business transformation with partners"></ListItem></li>
                <li><ListItem bgColor='white' text="Project managers"></ListItem></li>
                <li><ListItem bgColor='white' text="Youmoni IoT Cloud experts and developers"></ListItem></li>
                <li><ListItem bgColor='white' text="AI and machine learning experts"></ListItem></li>
                <li><ListItem bgColor='white' text="IoT hardware- and sensor engineers"></ListItem></li>
                <li><ListItem bgColor='white' text="User interface designers"></ListItem></li>
                <li><ListItem bgColor='white' text="Electronic engineers"></ListItem></li>
                <li><ListItem bgColor='white' text="Mechanical engineers and 3D printing expertise"></ListItem></li>
                <li><ListItem bgColor='white' text="Proof-of-concept prototyping and installations"></ListItem></li>
            </ul>
        </div>}
        content={
            <section className="px-3 px-md-5">
            <Container fluid="xl" className={'px-0 pt-4'}>
            <Row>
            <Col sm="12" md="12" xl="6" className={`pr-0 pr-md-6 mb-2 mb-md-5`}>
                <YmTextBlock
                    
                    header={"Youmoni and partner expertise"}
                    content={
                        <p>Our consulting team typically leads your IoT implementation project, adapts the Youmoni cloud and builds an edge solution with sensors that will be installed into or added to your things. Our partners' resources are often part of the expert teams to get the best mix of resources available. In some cases our implementation partners will lead the team and just a few experts from Youmoni will advise in a project.</p>
                    }
                ></YmTextBlock>
            </Col>
            <Col sm="12" md="12" xl="6" className={`pr-0 pr-md-6 mb-2 mb-md-5`}>
                <YmTextBlock
                    header={"Youmoni project methodology"}
                    content={<div>
                        <p>We use a practical methodology that focuses on IoT implementations and uses a agile approach with prototyping iterations of software and hardware as the core process. The projects are often divided into four main phases:</p>
                        <ul className={'list-unstyled'}>
                            <li>1. Business analysis – Objectives and business transformation using IoT.</li>
                            <li>2. Proof-of-concept. Bring a thing online and quickly prove the case.</li>
                            <li>3. Pilot. Focus on testing and tuning processes, hardware stability and data optimization.</li>
                            <li>4. Roll out. Tuned cloud. Integration. Optimized hardware, deployment and support processes.</li>
                        </ul>
                    </div>}
                ></YmTextBlock>
            </Col>
            </Row>
        </Container> 
        </section>
        
        }
    ></YmCollapseRow>
}

const FullSizeImage = (props:any): JSX.Element => {
    const expertiseImage = props.supportsWebp ? expertise2Webp :expertise2Jpg;

    return  <Container fluid="fluid">
        <Row>
            <Col xs="12" className={'p-0'}>
                <YmImageBlock
                    media={(
                        <div className="position-relative">
                            <div className={'cover-fixed'} style={{ height: '50vh', width: '100%', backgroundImage: `url(${expertiseImage})` }}></div>
                        </div>
                    )}
                ></YmImageBlock>
            </Col>
        </Row>
    </Container>
}

const FastTrack = (props:any): JSX.Element => {
    const fastTrackImage = props.supportsWebp ? fasttrackWebp :fasttrackJpg;
    return <div><YmCollapseRow
        bgColor={BgColor.WHITE}
        name={""}
        header={"Youmoni IoT Fast track"}
        ingress={<div>
            <p>The Youmoni product suite makes it easy to conduct a fast proof-of-concept.</p>
            <p>We think it's better to jump start and test live what's possible to achieve, rather than spend money on endless analysis phases of what technology to use.</p>
        </div>}
        content={
            <section className="px-3 px-md-5">
            <Container fluid="xl" className={'p-0 pt-4'}>
                <Row>
                    <Col xs="12">
                        <YmImageBlock
                            media={(
                                <div className="position-relative">
                                    <div style={{ height: '50vh', backgroundSize:"contain", backgroundRepeat:'no-repeat', backgroundPosition:'center', width: '100%', backgroundImage: `url(${fastTrackImage})` }}></div>
                                </div>
                            )}
                        ></YmImageBlock>
                    </Col>
                </Row>
            </Container>
            </section>
        }
    ></YmCollapseRow>
    </div>
}




const LabAndEmbedded = () :JSX.Element => {

    const items = [
        { title: "Youmoni Labs", image: '', text: "In our lab in Stockholm, Västberga we can build a prototype of your IoT solution, install it on your product(s) and do initial testing. The facility has a workshop, test sensors and ready-to-use IoT gateways for test purposes, as well as electronics test equipment, CAN simulation software, 3D printers etc." },
        { title: "Youmoni Embedded", image: '', text: "We also design custom embedded electronics for specific integration into products and/or to be able to integrate specific hardware and/or digital I/O. In high volume deployments and/or specific use cases, it’s often more cost efficient to design a specific PCB with CPU, I/O electronics and communication circuits, rather than using a standardized gateway or edge computer that's not 100% optimized for the specific case, or has the wrong form factor." },
    ]
    return <section className="px-3 px-md-5">
    <Container fluid="xl" className="px-0">
        <Row>
            {items.map((item, index) => {
                return <Col key={`intro_block_${index}`} className={`pr-0 pr-md-6 mb-2 mb-md-5`} sm="12" md="6">
                    <div className={'my-4'}>
                        <YmTextBlock
                            bgColor={BgColor.WHITE}
                            header={item.title}
                            content={item.text}
                        />
                    </div>
                </Col>
            })
            }
        </Row>
        </Container>
    </section>
}

const ImageDivider = (props:any): JSX.Element => {
    const managedIotImage = props.supportsWebp ? managedIoTWebp : managedIoTJpg;
    return  <Container fluid="fluid">
        <Row>
            <Col xs="12" className={'p-0'}>
                <YmImageBlock
                    media={(
                        <div className="position-relative">
                            <div className={'cover-fixed'} style={{ height: '50vh', width: '100%', backgroundImage: `url(${managedIotImage})` }}></div>
                        </div>
                    )}
                ></YmImageBlock>
            </Col>
        </Row>
    </Container>
}

const ManagedIot = (props:any) => {
    
    return <YmCollapseRow
        bgColor={BgColor.WHITE}
        header={"Youmoni managed IoT solutions – complex systems made simple."}
        ingress={<div>
            <p>Youmoni deliver a Managed IoT Service for customers that doesn't want any hassle in its operation. We take care of the end to end process.</p>
            <p>IoT is a complex network of cloud computing, wireless connectivity, remote hardware with edge computing and sensors. </p>
            <p>Roll out scenarios needs to be planned and managed, new things need attention to be integrated and others are replaced in a constant flow. We take care of that and at the same time monitors the constant flow of data. Sensors and IoT gateways also needsto be installed, tested and calibrated in the field in sync with cloud enrolment processes.</p>
            <p>The data flow and process chain need to be monitored and analyzed with intelligent automation. In the event of failures, the cloud and or the service organization need to discover if it's hardware, connectivity or software that needs correction.</p>

            <h2 className='text-primary mb-5'>Youmoni Managed IoT can include the following services</h2>
            <ul className='list-unstyled'>
                <li><ListItem bgColor='white' text="Fully managed service with Youmoni as main partner"></ListItem></li>
                <li><ListItem bgColor='white' text="Pay as you go when new things are connected"></ListItem></li>
                <li><ListItem bgColor='white' text="Fully managed Youmoni IoT Cloud"></ListItem></li>
                <li><ListItem bgColor='white' text="Managed and monitored connectivity via telecom partners"></ListItem></li>
                <li><ListItem bgColor='white' text="Monitored IoT gateways, edge computers and sensor data flow"></ListItem></li>
                <li><ListItem bgColor='white' text="Hardware delivery and installation services at selected locations"></ListItem></li>
                <li><ListItem bgColor='white' text="Integrated field support, often performed by partners"></ListItem></li>
                <li><ListItem bgColor='white' text="Service desk"></ListItem></li>
            </ul>
        </div>}
    >
    </YmCollapseRow>
}

const Expertise: React.FC<{match:any}> = (props:any) => {
    const expertiseImage = props.supportsWebp ? expertise1Webp :expertise1Jpg;
        return (<div className="expertise">
                <YmHeader
                 {...props}
                title="From sensors to business insight. We make sense of your things"
                media={<HeaderImage image={expertiseImage}></HeaderImage>}
                ></YmHeader>
                <IntroSection></IntroSection>
                <ExpertiseSection></ExpertiseSection>
                <FullSizeImage {...props}></FullSizeImage>
                <FastTrack {...props}></FastTrack>
                <LabAndEmbedded></LabAndEmbedded>
                <ImageDivider {...props}></ImageDivider>
                <ManagedIot></ManagedIot>
                <ContactUs></ContactUs>
            </div>
        );
    }
export default Expertise;