import React from 'react';
import { BgColor } from '../../models/BgColor';
import { YmCollapseRow } from '../../components/YmCollapseRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';


const Section1 = () => {
    return <YmCollapseRow
        bgColor={BgColor.WHITE}
        header={"Our promise goes further. Clarity a reality. More data, more opportunities, more clarity"}
        ingress={<div>
            <p>With your things connected and its data stored you will find new insights. You will learn how your products really work and behave in different situations and why they fail. You will even learn that they don't work as expected better in some scenarios worse in other. In short, connected products will give you new insights that will improve your offerings and understand how your customers really use them. </p>
            <p>Youmoni also offers to combine your own data with other external data e.g. weather data, financial data, geo data and or location data. By combining your own product data with external data you will understand even more. Maybe the product fails when its high humidity outside, or when the temperature is above 35 Celcius. </p>
            <p>If you partner with other companies you can also use one anothers data by sharing data in the Youmoni cloud. </p>
            <p>More data will bring more clarity and it will be your new reality.  </p>

            <p> <Link to="/products">Learn more about or IoT Platform<FontAwesomeIcon className="mx-2" size={'1x'} icon={faLongArrowAltRight}></FontAwesomeIcon></Link></p>
        </div>}>
    </YmCollapseRow>
}

const OurPromise: React.FC<{ match: any }> = (props) => {

    return (<div className="our promise">
        <Section1></Section1>
    </div>
    );
}

export default OurPromise;    