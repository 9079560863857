export class HubSpotService {

    private _newsLetterEndpoint = "https://api.hsforms.com/submissions/v3/integration/submit/5347146/ea513366-f4cd-478d-b4b2-3e4373173772";
    private _contactEndpoint = "https://api.hsforms.com/submissions/v3/integration/submit/5347146/36c63c20-710f-4993-8cf9-b927949e6b54";

    public signUpToNewsLetter = (email: string) => {

        let data: any = {
            context: {
                pageUri: window.location.pathname,
            },
            fields: [{ name: 'email', value: email }],
            legalConsentOptions: {
                legitimateInterest: {
                    value: true,
                    // This must be true when using the 'legitimateInterest' option, as it reflects the consent indicated by the visitor when submitting the form
                    subscriptionTypeId: 999,
                    // Integer; The ID of the specific subscription type that this forms indicates interest to.
                    legalBasis: "CUSTOMER",
                    // String, one of CUSTOMER or LEAD; Whether this form indicates legitimate interest from a prospect/lead or from a client.
                    text: "I agree to receive marketing communications from Youmoni."
                    // String; The privacy text displayed to the visitor.
                }
            }
        }

        let hutk = this.getCookie("hubspotutk");
        if (hutk) {
            data.context.hutk = hutk;
        }

        let promise = new Promise<boolean>((resolve, reject) => {
            let url = `${this._newsLetterEndpoint}`;
            fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: new Headers({
                    'Content-Type': 'application/json',

                }),
                body: JSON.stringify(data)
            }).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    reject(response.status);
                }
            }).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(undefined);
            });
        })
        return promise;
    }

    public signUpToContact = (formValues: any) => {

        let fields = Object.entries(formValues).map((entry) => {
            return { name: entry[0], value: entry[1] }
        });

        let data: any = {
            context: {
                pageUri: window.location.pathname,
            },
            fields: fields,
            legalConsentOptions: {
                legitimateInterest: {
                    value: true,
                    // This must be true when using the 'legitimateInterest' option, as it reflects the consent indicated by the visitor when submitting the form
                    subscriptionTypeId: 999,
                    // Integer; The ID of the specific subscription type that this forms indicates interest to.
                    legalBasis: "LEAD",
                    // String, one of CUSTOMER or LEAD; Whether this form indicates legitimate interest from a prospect/lead or from a client.
                    text: "I agree to be contacted by Youmoni and receive marketing communications from Youmoni."
                    // String; The privacy text displayed to the visitor.
                }
            }
        }

        let hutk = this.getCookie("hubspotutk");
        if (hutk) {
            data.context.hutk = hutk;
        }

        let promise = new Promise<boolean>((resolve, reject) => {
            let url = `${this._contactEndpoint}`;
            fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: new Headers({
                    'Content-Type': 'application/json',

                }),
                body: JSON.stringify(data)
            }).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    reject(response.status);
                }
            }).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(undefined);
            });
        })
        return promise;
    }

    public scheduleSmartCity = (formValues: any) => {

        let fields = Object.entries(formValues).map((entry) => {
            return { name: entry[0], value: entry[1] }
        });

        let data: any = {
            context: {
                pageUri: window.location.pathname,
            },
            fields: fields,
            legalConsentOptions: {
                legitimateInterest: {
                    value: true,
                    // This must be true when using the 'legitimateInterest' option, as it reflects the consent indicated by the visitor when submitting the form
                    subscriptionTypeId: 999,
                    // Integer; The ID of the specific subscription type that this forms indicates interest to.
                    legalBasis: "LEAD",
                    // String, one of CUSTOMER or LEAD; Whether this form indicates legitimate interest from a prospect/lead or from a client.
                    text: "I agree to be contacted by Youmoni and receive marketing communications from Youmoni."
                    // String; The privacy text displayed to the visitor.
                }
            }
        }

        let hutk = this.getCookie("hubspotutk");
        if (hutk) {
            data.context.hutk = hutk;
        }

        return new Promise<boolean>((resolve, reject) => {
            let url = `${this._contactEndpoint}`;
            fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: new Headers({
                    'Content-Type': 'application/json',

                }),
                body: JSON.stringify(data)
            }).then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    reject(response.status);
                }
            }).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(undefined);
            });
        });
    }

    private getCookie(name: string) {
        var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }
}
