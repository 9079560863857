import React from 'react';
import headerImg from '../../assets/img/youmoni-contact.jpg';
import { YmHeader, HeaderImage } from '../../components/YmHeader';
import { ContactUs } from '../../content/ContactUs';


const Contact: React.FC<{ match: any }> = (props) => {

    return (<div>
        <YmHeader {...props} title="Contact us regarding IoT technology" media={<HeaderImage image={headerImg}></HeaderImage>}></YmHeader>
        <ContactUs></ContactUs>
    </div>
    );
}

export default Contact;    