import webpSupport from '../assets/img/webpSupport.webp';

export const hasWebpSupport =() => {
    let promise = new Promise<boolean>((resolve, reject) => {
        try {
            var webp = new Image();
            webp.onerror = () => {
                reject(false);
            };
            webp.onload = () => {
                resolve(true);
            };
            webp.src = webpSupport;
        }
        catch (ex) {
            reject(false)
        }
    });


    return promise
}

export default hasWebpSupport;
