import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import './App.css';
import Routes from './routes/Routes'
import { I18n } from 'react-polyglot';
import texts from './content/Texts.json'
import { BrowserRouter as Router } from 'react-router-dom';
import { useTracking } from "./utils/useTracking";
import hasWebpSupport from  "./utils/imageHelper";


const App = (props: any) => {
  useTracking('UA-172162261-1')
  return (
    <I18n locale={'en'} messages={texts}>
      <Routes {...props} ></Routes>
    </I18n>
  );
}

export interface AppState {
  isLoading:boolean;
  supportsWebp:boolean;
}

export default class extends React.Component<{},AppState> {
  constructor(props:any){
    super(props);
    this.state = { 
      isLoading : true,
      supportsWebp:false
    }
  }
  

  componentDidMount(){
    hasWebpSupport().then((response:boolean) => {
      this.setState({supportsWebp:response,isLoading:false});
    }).catch(()=> {
      this.setState({supportsWebp:false,isLoading:false})
    });
  }

  render() {
    const {isLoading, supportsWebp} = this.state;
    return ( !isLoading ?
      <Router>
        <App supportsWebp={supportsWebp} ></App>
      </Router>:<div></div>
    );
  }
}

