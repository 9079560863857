import React from 'react';
import { Container, Row, Col, Input, Button } from 'reactstrap';
import { YmModal } from '../components/YmModal/YmModal';
import { HubSpotService } from '../services/hubSpotService';

export interface ContactFormState {
    data: any;
    fieldErrors: any;
    isModalOpen: boolean;
}

export interface ContactFormProps { }

const formFields = [
    {
        type: "text",
        name: "email",
        placeholder: "Email",
        required: true,
        errorMessage: "Mandatory field"
    },
    {
        type: "text",
        name: "firstname",
        placeholder: "First name",
        required: false,
        errorMessage: "Mandatory field"
    },
    {
        type: "text",
        name: "lastname",
        placeholder: "Last name",
        required: false,
        errorMessage: "Mandatory field"
    },
    {
        type: "text",
        name: "company",
        placeholder: "Company name",
        required: false,
        errorMessage: "Mandatory field"
    },
    {
        type: "textarea",
        name: "message",
        placeholder: "Tell us what you want to know, Thanks in advance!",
        required: false,
        errorMessage: "Mandatory field"
    },
];

export class ContactForm extends React.Component<ContactFormProps, ContactFormState> {

    private _hubspotService: HubSpotService;
    constructor(props: ContactFormProps) {
        super(props);
        this.state = {
            data: {},
            fieldErrors: {},
            isModalOpen: false
        }

        this._hubspotService = new HubSpotService();
    }

    public componentDidMount() {
        let data: any = {};
        formFields.forEach((field) => {
            data[field.name] = ''
        });
        this.setState({ data: data });
    }


    private onValueChanged = (event: React.ChangeEvent<any>) => {
        let fieldName = event.target.name;
        let newValue = event.target.value;

        const currentField = formFields.filter((fieldItem) => fieldItem.name === fieldName)[0];
        this.setState((prevState, props) => {
            return {
                ...prevState,
                data: { ...prevState.data, [fieldName]: newValue },
                fieldErrors: {
                    ...prevState.fieldErrors, [fieldName]: {
                        inValid: (currentField.required) && !newValue ? true : false,
                        message: 'Field is required'
                    }
                }
            }
        });
    }

    private onSubmit = () => {
        let { data } = this.state;
        if (this.isFormValid()) {

            this._hubspotService.signUpToContact(data).then((response) => {

                let data: any = {};
                formFields.forEach((field) => {
                    data[field.name] = ''
                });

                this.setState({ data: data, isModalOpen: true });
            });
        }
    }

    private isFormValid = () => {
        let { data } = this.state;
        let isValid = false;
        let requiredFields = formFields.filter((field) => field.required);

        let emptyFileds: any = {};
        requiredFields.filter((field) => data[field.name] === '' || data[field.name] === null).map((field) => emptyFileds[field.name] = { inValid: true, message: 'Field is required' });

        this.setState((prevState) => {
            return {
                ...prevState,
                fieldErrors: emptyFileds
            };
        },
        );

        isValid = !Object.keys(emptyFileds).length;
        if (!isValid) {
            return isValid;
        }

        //Format Validations
        let wrongFormat: any = {};
        var emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?w+)*(.\w{2,3})+$/;
        if (data.email) {
            if (!data.email.match(emailFormat)) {
                wrongFormat.email = { inValid: true, message: 'Wrong email format' };
                isValid = false
            }
        }
        this.setState((prevState) => {
            return {
                ...prevState,
                fieldErrors: wrongFormat
            };
        },
        );

        return isValid;
    }

    public render() {
        return (
            <section className="bg-primary">
                <Container fluid="xl" className="">
                    <Row>
                        {
                            formFields.map((field, index) => {

                                let input = field.type === 'textarea' ?
                                    <Input
                                        name={field.name}
                                        value={this.state.data[field.name] ? this.state.data[field.name] : ''}
                                        placeholder={field.placeholder}
                                        type={'textarea'}
                                        onChange={this.onValueChanged}
                                        invalid={this.state.fieldErrors[field.name] && this.state.fieldErrors[field.name].inValid}
                                    ></Input> :
                                    <Input
                                        style={{ height: '40px' }}
                                        name={field.name}
                                        value={this.state.data[field.name] ? this.state.data[field.name] : ''}
                                        placeholder={field.placeholder}
                                        type={'text'}
                                        onChange={this.onValueChanged}
                                        invalid={this.state.fieldErrors[field.name] && this.state.fieldErrors[field.name].inValid}
                                    ></Input>
                                return <Col key={`formfield_${index}`} sm="12" className="mt-4 pr-3">
                                    {input}
                                    <div>
                                        {
                                            this.state.fieldErrors[field.name] && this.state.fieldErrors[field.name].inValid &&
                                            <div style={{ fontSize: '16px' }} className="text-danger">{this.state.fieldErrors[field.name] ? this.state.fieldErrors[field.name].message : ''}</div>
                                        }
                                    </div>
                                </Col>
                            })
                        }
                        <Col sm="12" className="mt-4 text-left mb-5"><Button onClick={this.onSubmit} size="lg" color={"secondary"} style={{ borderRadius: '10rem', height: '40px' }} className="btn-pill px-4 py-1">Send</Button></Col>
                    </Row>
                </Container>
                <YmModal
                    isOpen={this.state.isModalOpen}
                    title={'Thank you!'}
                    text="Thank you for contacting us! We will get in touch with you as soon as possible"
                    toggleModal={() => this.setState({ isModalOpen: !this.state.isModalOpen })}
                ></YmModal>
            </section>

        )
    }
}