import React from 'react';
import { YmTextBlockProps } from './YmTextBlockProps';
import { BgColor } from '../../models/BgColor';

export const YmTextBlock: React.FunctionComponent<YmTextBlockProps> = props => {

    let textColorClass = "text-normal";
    let headerColorClass = "text-primary";
    switch (props.bgColor) {
        case BgColor.PRIMARY:
            headerColorClass = "text-white";
            textColorClass = "text-white"
            break;
        case BgColor.LIGHT:
            headerColorClass = "text-primary";
            textColorClass = "text-black";
            break;
        case BgColor.WHITE:

            headerColorClass = "text-primary";
            textColorClass = "text-black";
            break;
        default:
            textColorClass = "text-black";
            break;
    }
    return <div>
        <h3 className={`${headerColorClass} `}>{props.header}</h3>
        <div className={`${textColorClass}`}>{props.content}</div>
    </div>
}
export default YmTextBlock;