import React, { useState } from 'react';
import { Container, Row, Col, Collapse } from 'reactstrap';
import { YmTextBlock } from '../../components/YmTextBlock';
import { YmCollapseRow } from '../../components/YmCollapseRow';
import { BgColor } from '../../models/BgColor';
import TopNavigation from '../../components/TopNavigation';
import { YmImageBlock } from '../../components/YmImageBlock';
import { Link } from 'react-router-dom';

import softwareJpg from '../../assets/img/products/youmoni-software.jpg';
import softwareWebp from '../../assets/img/products/youmoni-software.webp';
import hardwareJpg from '../../assets/img/products/youmoni-hardware.jpg';
import hardwareWebp from '../../assets/img/products/youmoni-hardware.webp';
import productsHardwareJpg from '../../assets/img/products/youmoni-products-hardware.jpg'
import productsHardwareWebp from '../../assets/img/products/youmoni-products-hardware.webp'

import youmoniSensePng from '../../assets/img/products/youmoni-sense.png'
import youmoniSenseWebp from '../../assets/img/products/youmoni-sense.webp'
import youmoniDataPlugPng from '../../assets/img/products/youmoni-dataplug.png'
import youmoniDataPlugWebp from '../../assets/img/products/youmoni-dataplug.webp'
import youmoniSpaceinventoPng from '../../assets/img/products/youmoni-space-invento.png'
import youmoniSpaceinventoWebp from '../../assets/img/products/youmoni-space-invento.webp'

import overviewJpg from '../../assets/img/products/youmoni-products-software.jpg';
import overviewWebp from '../../assets/img/products/youmoni-products-software.webp';
import productSuiteImage from '../../assets/img/products/youmoni-product-suite.png'
import productOnion from '../../assets/img/products/youmoni-onion.png'
//HW
// webp
import industryHwJpg from '../../assets/img/products/youmoni-industry-grade.png'
import industryHwWebp from '../../assets/img/products/youmoni-industry-grade.webp'
import arduinoJpg from '../../assets/img/products/youmoni-arduino.png'
import arduinoWebp from '../../assets/img/products/youmoni-arduino.webp'
import raspberryJpg from '../../assets/img/products/youmoni-raspberry.png'
import raspberryWebp from '../../assets/img/products/youmoni-raspberry.webp'
import multi1Jpg from '../../assets/img/products/youmoni-multipurpose-1.png'
import multi1Webp from '../../assets/img/products/youmoni-multipurpose-1.webp'
import multi2Jpg from '../../assets/img/products/youmoni-multipurpose-2.png'
import multi2Webp from '../../assets/img/products/youmoni-multipurpose-2.webp'
import vehicle1Jpg from '../../assets/img/products/youmoni-vehicle-focused-1.png'
import vehicle1Webp from '../../assets/img/products/youmoni-vehicle-focused-1.webp'
import vehicle2Jpg from '../../assets/img/products/youmoni-vehicle-focused-2.png'
import vehicle2Webp from '../../assets/img/products/youmoni-vehicle-focused-2.webp'
import asset1Jpg from '../../assets/img/products/youmoni-asset-vehicle-tracker.png'
import asset1Webp from '../../assets/img/products/youmoni-asset-vehicle-tracker.webp'
import asset2Jpg from '../../assets/img/products/youmoni-asset-tracker.png'
import asset2Webp from '../../assets/img/products/youmoni-asset-tracker.webp'
import personalJpg from '../../assets/img/products/youmoni-personal-tracker.png'
import personalWebp from '../../assets/img/products/youmoni-personal-tracker.webp'


//Sensors
import temperatureJpg from '../../assets/img/products/youmoni-temperature-sensor.png'
import temperatureWebp from '../../assets/img/products/youmoni-temperature-sensor.webp'
import pressureJpg from '../../assets/img/products/youmoni-pressure-sensor.png'
import pressureWebp from '../../assets/img/products/youmoni-pressure-sensor.webp'
import diffJpg from '../../assets/img/products/youmoni-diff-pressure-sensor.png'
import diffWebp from '../../assets/img/products/youmoni-diff-pressure-sensor.webp'
import distanceJpg from '../../assets/img/products/youmoni-distance-sensor.png'
import distanceWebp from '../../assets/img/products/youmoni-distance-sensor.webp'
import { ContactUs } from '../../content/ContactUs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';


const sense = {
    title: "Youmoni Sense",
    paragraphs: [
        "Youmoni Sense, a multi-purpose IoT edge platform featuring onboard sensors, communication (Wi-Fi, 5g), and embedded controller. The platform can act as a stand-alone sensor and data gateway for multiple IoT use cases in different business verticals",
        "Its design, integrated sensors onboard relays and external connector make it ideal in smart city-, smart property, and industry projects for monitoring and controlling things. It is also ideal for predicative maintenance and service applications for connected remote products.",
        "Using one type of sensor platform for multiple use cases makes deployment, service and roll out easier and more cost effective."
    ],
    imgText: "Youmoni Sense (HV3) - multi-purpose sensor, gateway, and controller with onboard, sensors, relays, and communication (Wi-Fi, BLE, optional 5g). Designed for machine automation, connected products and remote monitoring, industrial IoT, smart city and property applications.",
    applications: [
        "Connected products. Data logging. Remote monitoring and control, predictive maintenance, remote service.",
        "Connected industrial machines, electrical engines, pumps, and compressors. Digitize analogue machines (RS232) or connect digital machines to the cloud.",
        "Smart city solutions. Monitoring, infrastructure, traffic, pedestrians, environmental data.",
        "Smart property. Energy and environmental monitoring, usage, access control, online apartments.",
        "Level metering and Prescence using the onboard radar sensor from our partner Acconeer."
    ],
    applicationParagraphs: [
        "The sensor platform is integrated with Youmoni IoT Cloud and supports several connectivity standards such as Wi-Fi, Bluetooth Mesh and 4g/5g modem as optional add-on cards. It can also communicate with external edge computers and gateways using RS232 or CAN.",
    ],
    bullets: [
        { title: "Embedded Software", bullets: ["Youmoni Embedded Platform using Free RTOS", "MQTT(s) protocol standard to Youmoni IoT Cloud"] },
        { title: "Operating voltage", bullets: ["6-30V DC or 3.7V LiPo battery"] },
        { title: "Wireless connectivity", bullets: ["Wi-Fi 4 & Bluetooth LE 5.0", "LTE Cat M1 / NB-IoT with expansion card"] },
        { title: "Analog inputs", bullets: ["4x configurable 0-30VDC / 4-20mA"] },
        { title: "Digital I/O", bullets: ["2x 5V and 1x 3.3V tolerant"] },
        { title: "Onboard sensors", bullets: ["Acconeer A111 Radar Air Temp & Humidity", "Accelerometer", "Microphone"] },
        { title: "Relay outputs", bullets: ["2x latching relays", "Max. 1A/30VAC/30VDC."] },
    ]
}

const dataplug = {
    title: "Youmoni DataPlug",
    paragraphs: [
        "Youmoni DataPlug is a multi-purpose IoT edge platform specially designed for data integration from machine to cloud in industrial CAN networks, vehicles, boats, and properties. It has a dual CPU design with multiple I/O support. It also features on board sensors and relays for controlling things",
        "Communication includes Wi-Fi, Bluetooth Mesh and 4/g5g using external modem or using an optional onboard module (H1 2024). The platform can act as a stand-alone data gateway for multiple IoT use cases in different business verticals.  ",
        "Its design with external Phoenix connectors makes it ideal in smart city, smart property, and industry projects for monitoring and controlling things. It is also ideal for predicative maintenance and service applications for connected remote products.",
        "The Modbus RTU support is extremely useful in property and PLC applications and the dual CAN interfaces its perfect for industrial and vehicle applications and the software supports e.g., CAN open, J1939 and NMEA, GPS etc. "
    ],
    imgText: "Youmoni DataPlug (HV2) - multi-purpose Data integration card, gateway, and machine controller with onboard, sensors, relays, and communication (Wi-Fi, BLE, optional 5g (HV3)). Designed for machine, vehicle, property data integration. It can also be used for connected products and vehicles such as trucks, buses, and boats. It works in many industrial data logging, with remote monitoring and predictive maintenance applications.",
    applications: [
        "Connected products. Data logging. Remote monitoring and control, predictive maintenance, remote service",
        "Connected industrial machines, PLC, CAN networks, digitize analogue machines (RS232).",
        "Smart property. Energy and environmental monitoring, access control, online apartments.",
        "Vehicle, truck, and boat applications acting as a 4g/5g gateway with GPS support, CAN, NEMA data from engine(s) etc. and it can establish a wireless sensor network for remote sensors onboard using Bluetooth. ",
    ],
    applicationParagraphs: [
        "DataPlug is integrated with Youmoni IoT Cloud. It can also communicate with external edge computers and gateways using RS232 or CAN",
    ],
    bullets: [
        { title: "Embedded Software", bullets: ["Youmoni Embedded Platform using Free RTOS", "MQTT(s) protocol standard to Youmoni IoT Cloud"] },
        { title: "Operating voltage", bullets: ["6-30V DC or 3.7V LiPo battery."] },
        { title: "Wireless connectivity", bullets: ["Wi-Fi 4 & Bluetooth LE 5.0", "LTE Cat M1 / NB-IoT with expansion card", "(HV3)"] },
        { title: "Serial connectivity", bullets: ["2x CAN-bus","RS-485 Modbus RTU","RS-232","1-wire"] },
        { title: "Digital I/O", bullets: ["2 x GPIO"] },
        { title: "Analog inputs", bullets: ["2x configurable 0-30VDC / 4-20mA"] },
        { title: "Relay outputs", bullets: ["2x latching relay","Max. 1A/30VAC/30VDC "] },
    ]
}

const spaceinvento = {
    title: "Youmoni Space Invento",
    paragraphs: [
        "Youmoni Space Invento is a small multipurpose sensor platform only 3 cm (about 1.18 in) in diameter. In standard configuration it is equipped with an advanced radar from Acconeer combined with temperature and humidity. Other combinations and adapted designs with other sensor components can be made. ",
        "It is equipped with Wi-Fi and Bluetooth Mesh communication. It Mesh with other Youmoni IoT hardware such as the Youmoni Sense and Youmoni DataPlug then acting as a gateway for the wireless sensors.",
        "The sensor board also have a CAN bus for industrial networking and can be powered via the CAN wire or using a LiPo battery."
    ],
    imgText: "Youmoni Space Invento (HV3) – ultra small radar sensor combined with temperature and humidity. Communication Wi-Fi, Bluetooth and wired CAN. Designed for machine automation, level metering, counting passing objects or people, presence detection etc.",
    applications: [
        "Distance, level metering, tank levels ",
        "Prescence detection of objects and people ",
        "Frequency-, movement-, speed detection, object counting e.g., in predictive maintenance or automation applications.",
        "Smart property applications, occupancy, space usage, people counting.",
        "Temperature and humidity monitoring",
        "Gesture control"
    ],
    applicationParagraphs: [
        "",
    ],
    bullets: [
        { title: "Operating voltage", bullets: ["5V DC or 3.7V LiPo battery"] },
        { title: "Wireless connectivity", bullets: ["Wi-Fi 4 & Bluetooth LE 5.0"] },
        { title: "Serial connectivity", bullets: ["CAN-bus 1x GPIO"] },
        { title: "Onboard sensors", bullets: ["Acconeer A121 Radar Air Temp & Humidity"] },
    ]
}



const YMProduct = (props: any) => {
    const { product, bgColor }: any = props;

    const [isOpen, toggle] = useState<boolean>(false)

    return <div className={`${bgColor ? bgColor : 'bg-light'} pt-5 pb-5`}><Container >
        <Row>
            <Col md={12} lg={6}> <div><h2 className="text-primary">{product.title} </h2></div>
                {product.paragraphs.map((item: any, index: number) => {
                    return <p key={index}>{item}</p>
                })}

                <h3 className='text-primary'>Typical applications</h3>
                {product.applications.map((item: any, index: number) => {
                    return <Row key={`r_${index}`}>
                        <Col className="col-auto pl-3 pr-0"><FontAwesomeIcon className={`text-primary`} size={'xs'} icon={faCheck}></FontAwesomeIcon></Col>
                        <Col>{item}</Col>
                    </Row>
                })}

                {product.applicationParagraphs.map((item: any, index: number) => {
                    return <p key={index}>{item}</p>
                })}

                <div className='mt-4 mb-5 d-none d-lg-flex'>
                    <span className={`text-primary cursor-pointer`} onClick={() => { toggle(!isOpen) }}>
                        {isOpen ? <span style={{ fontWeight: 600 }}><b>Let’s get technical  -</b></span> : <span style={{ fontWeight: 600 }}><b>Let’s get technical +</b></span>}
                    </span>
                </div>
            </Col>
            <Col md={12} lg={6}>
                <Row>
                    <Col className='text-left' xs={{ size: 8, offset: 2 }}> <div>
                        <img style={{width:400, height:'100%'}} alt={''} src={product?.img}></img>
                    {/* <div style={{ height: '400px', width: '100%', backgroundSize: 'contain', backgroundPosition: 'top', backgroundRepeat: 'no-repeat', backgroundImage: `url('${product?.img}')` }}>
                        
                    </div> */}
                        <div style={{ fontSize: 16, lineHeight: 1, fontStyle: 'italic' }} className='mt-4 pl-4  ' >
                            {product.imgText}
                        </div>
                    </div></Col>
                </Row>
            </Col>
        </Row>
        <Row>
            <Col>
            <div className='mt-4 mb-5 d-flex d-lg-none'>
                    <span className={`text-primary cursor-pointer`} onClick={() => { toggle(!isOpen) }}>
                        {isOpen ? <span style={{ fontWeight: 600 }}><b>Let’s get technical  -</b></span> : <span style={{ fontWeight: 600 }}><b>Let’s get technical +</b></span>}
                    </span>
                </div>
                <Collapse isOpen={isOpen}>
                    <Row>
                        {product.bullets.map((item: any, index: number) => {
                            return <Col className='mb-3' sm={3} md={4} xl={3}>
                                <h4 className='text-primary'>{item?.title}</h4>
                                {item.bullets.map((bullet: string, j: number) => {
                                    return <Row key={j}>
                                        <Col>{bullet}</Col>
                                    </Row>
                                })}

                            </Col>
                        })
                        }
                    </Row>
                </Collapse>
            </Col>
        </Row>
        <Row>
            <Col className={`pb-5`}></Col>
        </Row>
    </Container>
    </div>
}

const Header = (props: any): JSX.Element => {
    const softwareImage = props.supportsWebp ? softwareWebp : softwareJpg;
    const hardwareImage = props.supportsWebp ? hardwareWebp : hardwareJpg;

    return <div>
        <TopNavigation {...props} bgColor={BgColor.WHITE}></TopNavigation>
        <section className="px-3 px-md-5">
            <Container fluid={true}>
                <Row style={{ marginTop: '90px' }}>
                    <Col className="px-0 pr-md-3 pb-2 pb-md-0" sm="12" md="6">
                        <YmImageBlock
                            media={(<div className={'position-relative'} >
                                <div style={{ height: '80vh', width: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: `url('${softwareImage}')` }}></div>
                                <div className={'opacity-black'} style={{ height: '80vh', width: '100%', position: 'absolute', top: 0 }}></div>
                            </div>)}
                            header={<Link className="text-white" to="/products#software">IoT software cloud</Link>}
                        ></YmImageBlock>
                    </Col>
                    <Col className="px-0 pl-md-3 pb-2 pb-md-0" sm="12" md="6">
                        <YmImageBlock
                            media={(
                                <div className={'position-relative'}>
                                    <div style={{ height: '80vh', width: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: `url('${hardwareImage}')` }}></div>
                                    <div className={'opacity-black'} style={{ height: '80vh', width: '100%', position: 'absolute', top: 0 }}></div>
                                </div>
                            )}
                            header={<Link className="text-white" to="/products#hardware">Hardware</Link>}
                        ></YmImageBlock>
                    </Col>
                </Row>
            </Container>
        </section>
    </div>
}

const ProductsOverview = (props: any): JSX.Element => {
    const overviewImage = props.supportsWebp ? overviewWebp : overviewJpg;
    return <div><YmCollapseRow
        bgColor={BgColor.WHITE}
        name={"Youmoni product suite"}
        header={"Overview of product suite"}
        ingress={<div>
            <p>Youmoni delivers a complete product suite for IoT solutions. It includes multi tenant IoT cloud and hardware products. Ready-to-use applications can be used from start and often only need an adapted brand experience. With ready-to-use applications you can be up and running in a days if we have the hardware in stock. Custom IoT applications are built very fast using the integrated product suite.</p>
            <p>Cloud and edge computing software are designed and engineered by the Youmoni tech team in Sweden. Hardware products such as gateways and sensors are a mix of 3rd party integrated products, sometimes combined with Youmoni-developed sensor technology and embedded electronics.</p>
            <p>With the integrated product suite Youmoni and implementation partners can rapidly deliver, cutting edge, end-to-end IoT solutions. From connected sensors to digitized business processes in the cloud.</p>
        </div>}
        content={
            <Container fluid>
                <Row>
                    <Col xs="12" className={'text-center'}>
                        <div style={{ height: '70vh', backgroundSize: "contain", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: '100%', backgroundImage: `url(${productSuiteImage})` }}></div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className={'p-0'}>
                        <YmImageBlock
                            media={(
                                <div className="position-relative">
                                    <div className={'cover-fixed'} style={{ height: '50vh', width: '100%', backgroundImage: `url('${overviewImage}')` }}></div>
                                </div>
                            )}
                        ></YmImageBlock>
                    </Col>
                </Row>
            </Container>
        }
    ></YmCollapseRow>
    </div>
}

const CloudEdgeSoftWare = (props: any): JSX.Element => {
    const items = [
        { title: "Fast time-to-market", text: "No overlapping development delivers fast time-to-market. Proof of concepts can be conducted with ready-to-use cloud applications and already integrated hardware." },
        { title: "Focusing on your thing", text: "The Youmoni cloud focuses on business objects, things and assets rather than technical IoT devices. The cloud presents everything in business context, not only in device context. Business integration is also made easier due to the things platform product model and data platform with product and meta data storage services for business objects." },
        { title: "User interfaces", text: "Business users interact with a user friendly and branded dashboard portal adapted for the product items relevant in your business processes. Business users authenticate in the Youmoni cloud or can authenticate by using a public or private cloud account such as an Office 365 business account. Each user gets its authorized level of access rights controlled by a roled based account management system." },
        { title: "End users", text: "Interactions with end users are easily integrated and delivered. It could be use cases where end users interact in apps and commit transactions, opening doors, buying products in vending machines, or starting a fan in a room." },
        { title: "Interoperability and integration", text: "The platform already supports Youmoni selected and integrated hardware, but can also interoperate with other third-party devices and services that use public IoT standards such as MQTT. All APIs are REST APIs and integrations with business systems such as ERP, MDM and PIM systems are easily made." },
        { title: "Highly flexible with modular structure", text: "As needed, our modular platform can be applied as a whole or in parts, which makes it flexible to respond to ever-changing customer and market requirements." },
        { title: "Applicable to many verticals", text: "The platform can be used in any vertical, including automobile, healthcare, home appliance, smart city, property management, industry and manufacturing, power and logistics. We deliver ready to use business applications in several verticals, including tracking, cold chain fleet management, remote monitoring and automated retail." },
        { title: "Tech", text: "The Youmoni IoT cloud is a multi tenant platform developed for maximum flexibility and scalability. It's designed and developed in Sweden and the software stack runs in public or private cloud infrastructure. By default the Youmoni IoT platform runs in AWS infrastructure but customers can move their Youmoni IoT platform instances to private clouds or even to on-prem environments. This is important related to security compliance e.g. in government, smart city and utility/energy applications. The Youmoni IoT Platform core backend services uses event sourcing and are written in Scala. The presentation frameworks uses React. Microservices in the backend environment with autoscaling makes the system resilient and scalable." },
        { title: "Youmoni edge computing product", text: "The Youmoni edge computing stack runs on Linux based edge computers and IoT gateways. It secures the communication to the Youmoni cloud and has ready-to-use system services for heartbeat, mobile network communication status, GPS positioning and sensor data classification and communication. The edge software has support for wireless sensors and wired analogue and digital sensors. The edge computing stack also integrate sensor hubs using e.g. the 1-Wire, Modbus or CAN protocols." },


    ]
    return <div><YmCollapseRow
        bgColor={BgColor.LIGHT}
        name={""}
        header={"Youmoni IoT platform"}
        ingress={<div>
            <p>Youmoni IoT cloud is an end-to-end cloud that enables you to bring IoT services to the market faster. It will drive your digitalization of physical business processes forward and enable you to really enable your IoT business models. Quickly move from idea to prototype, proof of concept and roll-out with scale.</p>
            <p>Our cloud is multi-tenant with an open design and branding layer. It can be customized to any brand, and supports white labeling so that your customers IoT solution can be delivered within your tenant of the Youmoni cloud. Simliar to the shop-in-shop solutions often used in e-commerce, but here it's IoT in IoT cloud.</p>
            <p>The cloud has ready-to-use business applications for fleet and vehicle management, cold chain monitoring, remote monitoring and control to automated retail solutions with automated inventory, payments and more.</p>
            <p>It has a modular structure with device platform, things platform, data platform and service platform. Custom IoT applications can easily be configured and developed using the full service stack."</p>
        </div>}
        content={
            <div>
                <Container fluid style={{ backgroundColor: '#052dc6' }} >
                    <Row>
                        <Col xs="12" className={'text-center'}>
                            <div style={{ height: '70vh', backgroundSize: "contain", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: '100%', backgroundImage: `url(${productOnion})` }}></div>
                        </Col>
                    </Row>
                </Container>
            </div>
        }
        collapseContent={
            <section className="px-3 px-md-5 pb-5">
                <Container fluid="xl" className="px-0">
                    <Row>
                        {
                            items.map((item, index) => {
                                return <Col key={`items_${index}`} sm="6" md="6" lg="4" className="pr-0 pr-md-6 mb-2 mb-md-5">
                                    <YmTextBlock header={item.title} content={item.text}></YmTextBlock>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        }
    ></YmCollapseRow>

    </div>
}

const HardWare = (props: any): JSX.Element => {
    const productsHardwareImage = props.supportsWebp ? productsHardwareWebp : productsHardwareJpg;
    const industryHwImage = props.supportsWebp ? industryHwWebp : industryHwJpg;
    const vehicle1Image = props.supportsWebp ? vehicle1Webp : vehicle1Jpg;
    const vehicle2Image = props.supportsWebp ? vehicle2Webp : vehicle2Jpg;
    const raspberryImage = props.supportsWebp ? raspberryWebp : raspberryJpg;
    const arduinoImage = props.supportsWebp ? arduinoWebp : arduinoJpg;
    const multi1Image = props.supportsWebp ? multi1Webp : multi1Jpg;
    const multi2Image = props.supportsWebp ? multi2Webp : multi2Jpg;
    const asset1Image = props.supportsWebp ? asset1Webp : asset1Jpg;
    const asset2Image = props.supportsWebp ? asset2Webp : asset2Jpg;
    const personalImage = props.supportsWebp ? personalWebp : personalJpg;

    const items = [
        { title: "Industry grade", text: "", image: industryHwImage },
        { title: "Vehicle focused", text: "", image: vehicle1Image },
        { title: "Vehicle focused", text: "", image: vehicle2Image },
        { title: "Raspberry Pi", text: "", image: raspberryImage },
        { title: "Arduino", text: "", image: arduinoImage },
        { title: "Modular multi purpose gateways and modems", text: "", image: multi1Image },
        { title: "Modular multi purpose gateways and modems", text: "", image: multi2Image },
        { title: "Asset and vehicle trackers", text: "", image: asset1Image },
        { title: "Asset and vehicle trackers", text: "", image: asset2Image },
        { title: "Personal tracker", text: "", image: personalImage }
    ]
    return <div><YmCollapseRow
        bgColor={BgColor.WHITE}
        name={""}
        header={"Hardware and edge software"}
        ingress={<div>
            <p>Youmoni has tested and integrated a range of IoT gateways and edge computers. They all fulfill different purposes. Some are multi-purpose units for property management and industry applications, while others are more specialized for vehicles with embedded accelerometers and GPS location.</p>
            <p>Youmoni has selected and integrated a range of gateways that can be used with several modems and connect to many different networks. All gateways support the networks that Youmoni provide access in. Typically we use telecom data networks using 2G/3G/4G, 4G CAT M1 or NB -IoT communicaiton standards.</p>
            <p>Some gateways also supports open LoRaWAN, Sigfox networks and LAN or WiFi for local network applications.</p>
            <p>All Youmoni supplied gateways are integrated with our cloud. This means that they run software that integrates essential sensors and can communicate with Youmoni cloud using selected networks out of the box.</p>
            <p>Youmoni works closely with hardware partners to be able to deliver IoT solutions in multiple industry verticals. We have close cooperation with especially edge computer and gateway manufacturers such as Kontron and Techbase, tracking (Digital matter, Outsmart), sensor and sensor chip suppliers such as Acconeer.  </p>
        </div>}
        content={
            <div>
                <Container fluid>
                    <Row>
                        <Col xs="12" className={'p-0'}>
                            <YmImageBlock
                                media={(
                                    <div className="position-relative">
                                        <div className={'cover-fixed'} style={{ height: '50vh', width: '100%', backgroundImage: `url(${productsHardwareImage})` }}></div>
                                        <div className='opacity-black position-absolute' style={{ height: '50vh', width: '100%', top: 0 }}></div>
                                    </div>
                                )}
                            ></YmImageBlock>
                        </Col>
                    </Row>
                </Container>
            </div>
        }
        collapseContent={
            <section className="px-3 px-md-5 pb-5">
                <Container fluid="xl">
                    <Row>
                        <Col lg="9" className="pb-5 px-0">
                            <h2 className="text-primary">Example of Youmoni IoT Gateways and edge computers</h2>
                            <p>These are examples of some of the gateways and edge computers that currently are integrated into Youmoni packaged solutions, or are used as baseline in customer specific IoT projects. These IoT gateways either run the Youmoni egde computing stack or use a Youmoni adapted embedded OS that connects to the Youmoni cloud.</p>
                            <div></div>
                        </Col>
                    </Row>
                    <Row>
                        {
                            items.map((item, index) => {
                                return <Col key={`items_${index}`} sm="6" md="6" lg="4" className="mb-5">

                                    <div>
                                        <div className="p-4 w-100 text-center">
                                            <div style={{ height: '150px', width: '100%', backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: `url('${item.image}')` }}></div>

                                        </div>
                                        <div className="pt-4 text-center">
                                            <YmTextBlock header={item.title} content={item.text}></YmTextBlock>
                                        </div>
                                    </div>

                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        }
    ></YmCollapseRow>

    </div>
}

const YMHardware = (props: any): JSX.Element => {

    return <div><YmCollapseRow
        bgColor={BgColor.WHITE}
        name={""}
        header={"Youmoni IOT hardware"}
        ingress={<div>
            <p>Youmoni has developed an IoT hardware product range with Youmoni embedded edge software that we think is missing in the market. We follow our strategy to be able to deliver IoT end to end from sensor to cloud and apps. Our product range makes it easy to deliver and deploy IoT solutions at scale. Customer hardware adaptations are easy to make and with the Youmoni embedded software (C, C++) middleware, based upon FreeRTOS the software is also easy to adapt to customer specific use cases. The embedded software also connects with the Youmoni Service App that makes it easy to connect to Youmoni hardware using Bluetooth, verify status, change configuration, see customer asset documentation and even update (OTA) the embedded software.  </p>
            <p>Youmoni now also has the capability to produce hardware and source components so that we can cope with changes in the global supply chain. We can manufacture our IoT hardware locally in Sweden, in the EU or global using suppliers in Asia.  </p>
            <b style={{ fontWeight: 'bold' }}>Cloud integration</b>
            <p>Youmoni hardware integrates seamlessly with Youmoni IoT platform.</p>
        </div>}
        content={
            <></>
        }

    ></YmCollapseRow>
    </div>
}


const YMIOTHardware = (props: any): JSX.Element => {



    return <div>
        <YMProduct  divider={true} product={{...sense, img : props.supportsWebp ?  youmoniSenseWebp : youmoniSensePng }}></YMProduct>
        <YMProduct  bgColor={BgColor.WHITE} divider={true} product={{...dataplug, img : props.supportsWebp ?  youmoniDataPlugWebp : youmoniDataPlugPng}}></YMProduct>
        <YMProduct  product={{...spaceinvento,img : props.supportsWebp ?  youmoniSpaceinventoWebp : youmoniSpaceinventoPng}}></YMProduct>
    </div>
}

const Sensors = (props: any): JSX.Element => {
    const distanceImage = props.supportsWebp ? distanceWebp : distanceJpg;
    const temperatureImage = props.supportsWebp ? temperatureWebp : temperatureJpg;
    const pressureImage = props.supportsWebp ? pressureWebp : pressureJpg;
    const diffImage = props.supportsWebp ? diffWebp : diffJpg;
    const items = [
        { title: "Ultrasonic distance sensor", text: "", image: distanceImage },
        { title: "Temperature sensor", text: "", image: temperatureImage },
        { title: "Pressure sensor", text: "", image: pressureImage },
        { title: "Diff pressure sensor", text: "", image: diffImage },
    ]

    const extra = [
        { title: "Youmoni designed sensors", text: "Youmoni has designed sensors for automated retail, e.g. we have sensors for automation of shelves in stores or remote warehouses such as VMI stocks. Pat. pending. ", },
        { title: "Embedded electronics design and manufacturing", text: "If no sensors can be found that fulfill the use case, if the IoT solution has size constraints, or needs to be delivered in high volumes, Youmoni also designs embedded electronics. The PCB and circuits can be connected to existing data ports and tap data and add sensing devices into new or existing products. Ask us - We can design custom hardware modules for your product that grab the data needed.", }
    ]

    return <div><YmCollapseRow
        bgColor={BgColor.WHITE}
        name={""}
        header={"Sensors"}
        ingress={<div>
            <p>Youmoni integrates and delivers 3rd party sensors needed in our solutions. Standardized and specific sensors are often used in custom IoT applications and the data from sensors can be combined with data from digital I/O from your own things. The number and the type of sensors needed depends on the scope of the IoT solution and what data is needed to fulfill the business scope.</p>
            <p>We have integrated more than 50 sensors, ranging from distance sensors with radar and ultrasound, temp and humidity, energy, vibration, location (GPS) and effect sensors to environmental data sensors for air (PM 2.5, PM10, CO2) and water (TDS) quality.</p>
        </div>}
        collapseContent={
            <section className="px-3 px-md-5 pb-5">
                <Container fluid="xl" className="px-0">
                    <Row>
                        <Col lg="9" className="pb-5">
                            <h2 className="text-primary">Example of Sensors </h2>
                            <div>Below you can see some example of sensors that Youmoni has integrated into ready to use IoT Gateways and in our cloud applications. </div>
                        </Col>
                    </Row>
                    <Row>
                        {
                            items.map((item, index) => {
                                return <Col key={`items_${index}`} sm="6" md="6" lg="3" className="mb-5">
                                    <div>
                                        <div className="p-4 w-100 text-center">
                                            <div style={{ height: '150px', width: '100%', backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: `url('${item.image}')` }}></div>
                                        </div>
                                        <div className="pt-4 text-center">
                                            <YmTextBlock header={item.title} content={item.text}></YmTextBlock>
                                        </div>
                                    </div>

                                </Col>
                            })
                        }
                    </Row>
                    <Row>
                        {
                            extra.map((item, index) => {
                                return <Col key={`items_${index}`} sm="6" md="6" className="mb-5">
                                    <div className="pt-5">
                                        <YmTextBlock header={item.title} content={item.text}></YmTextBlock>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        }
    ></YmCollapseRow>

    </div>
}

const softwareRef = React.createRef<HTMLDivElement>();
const hardwareRef = React.createRef<HTMLDivElement>();
const scrollInto = (location: any) => {
    if (location && location.hash) {
        let node: HTMLDivElement | null = null;
        switch (location.hash) {
            case '#software':
                node = softwareRef.current
                break;
            case '#hardware':
                node = hardwareRef.current
                break;
            default:
                break;
        }
        if (node) {
            node.scrollIntoView({ behavior: 'smooth' });
        }
    }
}

const Products: React.FC<{ match: any, location: any }> = (props) => {

    scrollInto(props.location)

    return (<div className="products">
        <Header {...props}></Header>
        <ProductsOverview {...props}></ProductsOverview>
        <div id="software" ref={softwareRef} ><CloudEdgeSoftWare {...props}></CloudEdgeSoftWare></div>
        <div id="hardware" ref={hardwareRef} ><HardWare {...props}></HardWare></div>
        <div id="ymhardware"><YMHardware {...props}></YMHardware></div>
        <div id="ymiothardware"><YMIOTHardware {...props}></YMIOTHardware></div>
        <div id="sensors"><Sensors {...props}></Sensors></div>
        <ContactUs></ContactUs>
    </div>
    );
}

export default Products;    